import React, { useState, useEffect } from 'react';
import { useParams } from "react-router";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from 'react-router-dom';
import {
  Button,
  Card,
  Checkbox,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Item,
  Image,
  Label,
  List,
  Menu,
  Message,
  Modal,
  Radio,
  Responsive,
  Row,
  Segment,
  Select,
  Sidebar,
  Statistic,
  Table,
  Visibility,
  Tab,
  Dropdown
} from 'semantic-ui-react'
import { ApolloProvider, useMutation, useQuery, writeQuery } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { gql } from 'apollo-boost';
import { onError } from "apollo-link-error";
import PrivateRoute from './privateroute';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import generatedGitInfo from './generatedGitInfo.json';

const httpLink = createHttpLink({
  uri: 'https://hasura.lggm.de/v1/graphql',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('auth-token');
  const token = localStorage.getItem('auth-token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward, response }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, extensions }) => {
        switch (extensions.code) {
          default:
            // default case
            toast.error(extensions.code);
            response.errors = undefined;
            response.data = false;
        }
      });
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      toast.error(networkError);
    }
  }
);

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache()
})

const LoginPage = () => {
  const [formState, setFormState] = useState({
    email: '',
    password: ''
  });

  const [messageState, setMessageState] = useState({
    message: '',
    type: '',
    active: false
  })

  let history = useHistory()

  //const userContext = useContext(UserContext);

  useEffect(() => {
    if (localStorage.getItem('auth-token')) {
      history.push('/');
    }
  })

  const handleFormChange = (e) => setFormState({
    ...formState,
    [e.target.name]: e.target.value,
  });

  const handleSubmit = () => {
    fetch('https://api.lggm.de/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formState)
    })
      .then(res => res.json())
      .then(data => {
        if (data.success) {
          console.log('success!');
          const { token } = data
          localStorage.setItem('auth-token', token)
          localStorage.setItem('email', formState.email)
          history.push(`/`)
        } else {
          console.log('oops! something went wrong')
          setMessageState({
            ...messageState,
            message: data.message,
            active: true
          })
        }
      });
  }

  return (
    <Grid textAlign='center' verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 450, width: 400 }}>
        <Message attached>
          <Header as='h2' color='teal' textAlign='center'>
            LGGM Administration
          </Header>
        </Message>
        <Message attached warning hidden={!messageState.active}>
          <Message.Header>{messageState.message}</Message.Header>
        </Message>
        <Form className='attached fluid segment' onSubmit={handleSubmit}>
          <Form.Input
            name='email'
            value={formState.email}
            onChange={handleFormChange}
            fluid
            icon='user'
            iconPosition='left'
            placeholder='E-mail address' />
          <Form.Input
            name='password'
            value={formState.password}
            onChange={handleFormChange}
            fluid
            icon='lock'
            iconPosition='left'
            placeholder='Password'
            type='password'
          />
          <Button type='submit' color='teal' fluid size='large'>
            Login
          </Button>
        </Form>
        {/* <Message attached='bottom' info>
          New to us? <Link to='/register'>Register here</Link>  &nbsp;&nbsp;|&nbsp;&nbsp; <Link to='/resetPasswordLink'>Reset your password</Link>
        </Message> */}
      </Grid.Column>
    </Grid>
  )
}

const AdminPageMenu = () => {
  const [state, setState] = useState();

  const handleItemClick = (e, { name }) => setState(name)

  let history = useHistory()

  return (
    <Menu vertical style={{ textAlign: 'center' }}>
      <Menu.Item
        name='home'
        active={state === 'home'}
        onClick={handleItemClick}
        as={Link}
        to='/'
      >
        Home
      </Menu.Item>
      <Menu.Item
        name='events'
        active={state === 'events'}
        onClick={handleItemClick}
        as={Link}
        to='/events'
      >
        {/* <Label color='teal'>1</Label> */}
        Events
      </Menu.Item>
      <Menu.Item
        name='users'
        active={state === 'users'}
        onClick={handleItemClick}
        as={Link}
        to='/users'
      >
        {/* <Label color='teal'>60</Label> */}
        Users
      </Menu.Item>
      {/* <Menu.Item
        name='captains'
        active={state === 'captains'}
        onClick={handleItemClick}
        as={Link}
        to='/captains'
      >
        {/* <Label color='teal'>60</Label> 
        Captains
      </Menu.Item> */}
      {/* <Menu.Item
        name='artists'
        active={state === 'artists'}
        onClick={handleItemClick}
        as={Link}
        to='/artists'
      >
        {/* <Label color='teal'>60</Label> 
        Artists
      </Menu.Item> */}

      {localStorage.getItem('auth-token') ? <Menu.Item
        name='logout'
      >
        <Button negative onClick={() => { localStorage.removeItem('auth-token'); localStorage.removeItem('email'); history.push('/') }}>Logout</Button>
      </Menu.Item> : null}
    </Menu>
  )
}

const USER_OVERVIEW_QUERY = gql`
{
  user(order_by: {lastName: asc}) {
    id
    firstName
    lastName
    email
    phone
    active
    emailreminders
    events {
      firstTime
      signedUp
      preferredRole
      preferredShift
      preferredBuddy
      arrivalDate
      arrivalTime
      arriveEarlier
      departureTime
      departureDate
      departLater
      biggerRole
      callMatrix
      callStatus
      assignedTo
      phase
    }
    acts {
      actName
      firstTime
      bandSize
      needsBed
      musicStyle
      callStatus
      created_at
    }
    tickets {
      transaction {
        status
        amountrefunded
      }
    }
    volunteerShifts {
      shift
      shiftType
      captain {
        id
        firstName
        lastName
        email
      }
      status
    }
    captainShifts {
      shift
      shiftType
      volunteer {
        id
        firstName
        lastName
        email
      }
      status
    }
  }
}
`;

const USER_PROFILE_QUERY = gql`
  query UserProfile($id: uuid!) {
    user(where: {id: {_eq: $id}}) {
      firstName
      lastName
      email
      phone
      active
      lastLogin
      userType
      volunteerShifts {
        shift
        shiftType
        captain {
          id
          firstName
          lastName
          email
        }
        status
      }
      captainShifts {
        shift
        shiftType
        volunteer {
          id
          firstName
          lastName
          email
        }
        status
      }
    }
  }
`;

const USER_EVENT_QUERY = gql`
  query UserEvent($userId: uuid!) {
    user_event(where: {userId: {_eq: $userId}}) {
      userId
      acceptRules
      firstTime
      attended
      preferredRole
      preferredShift
      preferredBuddy
      arrivalDate
      arrivalTime
      arriveEarlier
      departureTime
      departureDate
      departLater
      biggerRole
      remarks
      callMatrix
      signedUp
      updated_at  
      phase
      eventId
      callStatus
      assignedTo
      adminRemarks
    }
  }
`;

const USER_ACT_QUERY = gql`
  query UserAct($userId: uuid!) {
    user_act(where: {userId: {_eq: $userId}}) {
      userId
      eventId
      actName
      firstTime
      acceptRules
      bandSize
      estFuelCost
      guestList
      arrivalDate
      arrivalTime
      arriveEarlier
      departureDate
      departureTime
      departLater
      needsBed
      remarks
      created_at
      updated_at
      musicStyle
      musicPreview
      gearNeed
      gearBring
      feeEnough
      callStatus
    }
  }
`;

const USER_SHIFT_QUERY = gql`
  query UserShiftQuery {
    user {
      id
      firstName
      lastName
      active
      email
      phone
      events {
        adminRemarks
        assignedTo
        callStatus
        introducedBy
        preferredBuddy
        remarks
        updated_at
        userType
      }
      tickets {
        transaction {
          status
          amountrefunded
        }
      }
      volunteerShifts {
        shift
        shiftType
        captain {
          id
          firstName
          lastName
          email
        }
        status
      }
      captainShifts {
        shift
        shiftType
        volunteer {
          id
          firstName
          lastName
          email
        }
        status
      }
    }
  }
`;

const OVERVIEW_QUERY = gql`
  {
    users: user_aggregate {
      aggregate {
        count
      }
    }
    registrations: user_event_aggregate {
      aggregate {
        count
      }
    }
    confirmed: user_event_aggregate(where: {phase: {_eq: 2}}) {
      aggregate {
        count
      }
    }
    soldtickets: ticket_aggregate(where: {transaction: {status: {_eq: "paid"}, amountrefunded: {_eq: "0"}}}) {
      aggregate {
        count
      }
    }
  }
`;

const EVENT_QUERY = gql`
  {
    event {
      id
      eventName
      eventDate
      visible
    }
  }
`;

const UPDATE_USER_PROFILE = gql`
  mutation UpdateUserProfile(
    $userId: uuid!,
    $firstName: String!,
    $lastName: String!,
    $phone: String!,
    $active: Boolean!,
    $userType: String!
  ) {
    update_user(
      where: {id: {_eq: $userId}},
      _set: {
        firstName: $firstName,
        lastName: $lastName,
        phone: $phone,
        userType: $userType,
        active: $active
      }) {
      affected_rows
      returning {
        firstName
        lastName
        phone
        email
        active
        userType
      }
    }
  }
`

const UPDATE_USER_EVENT = gql`
  mutation UpdateUserEvent(
    $userId: uuid!,
    $acceptRules: Boolean!,
    $firstTime: Boolean!,
    $preferredRole: String!,
    $preferredShift: String!,
    $preferredBuddy: String!,
    $arrivalDate: String!,
    $arrivalTime: String!,
    $arriveEarlier: Boolean!,
    $departureDate: String!,
    $departureTime: String!,
    $departLater: Boolean!,
    $biggerRole: Boolean!,
    $remarks: String,
    $callStatus: String,
    $assignedTo: String,
    $phase: Int,
    $adminRemarks: String) {
    update_user_event(
      where: {userId: {_eq: $userId}},
      _set: {
        acceptRules: $acceptRules, 
        firstTime: $firstTime,
        preferredRole: $preferredRole,
        preferredShift: $preferredShift,
        preferredBuddy: $preferredBuddy,
        arrivalDate: $arrivalDate,
        arrivalTime: $arrivalTime,
        arriveEarlier: $arriveEarlier,
        departureDate: $departureDate,
        departureTime: $departureTime,
        departLater: $departLater,
        biggerRole: $biggerRole,
        remarks: $remarks,
        callStatus: $callStatus
        assignedTo: $assignedTo
        phase: $phase
        adminRemarks: $adminRemarks
      }) {
      affected_rows
      returning {
        userId
        acceptRules
        firstTime
        preferredRole
        preferredShift
        preferredBuddy
        arrivalDate
        arrivalTime
        arriveEarlier
        departureDate
        departureTime
        departLater
        biggerRole
        remarks
        callStatus
        assignedTo
        phase
        adminRemarks
        user {
          firstName
        }
      }
    }
  }
`

const UPDATE_USER_ACT = gql`
mutation updateUserAct(
  $userId: uuid!,
  $eventId: uuid!,
  $acceptRules: Boolean!,
  $firstTime: Boolean!,
  $actName: String!,
  $bandSize: Int,
  $estFuelCost: String,
  $guestList: String!,
  $arrivalDate: String!,
  $arrivalTime: String!,
  $arriveEarlier: Boolean!,
  $departureDate: String!,
  $departureTime: String!
  $departLater: Boolean!,
  $needsBed: Boolean!,
  $remarks: String,
  $musicStyle: String,
  $musicPreview: String,
  $gearNeed: String,
  $gearBring: String,
  $feeEnough: Boolean!,
  $callStatus: String,
  ) {
    insert_user_act(
      objects: [
        {
          userId: $userId
          eventId: $eventId
          acceptRules: $acceptRules
          firstTime: $firstTime
          actName: $actName
          bandSize: $bandSize
          estFuelCost: $estFuelCost
          guestList: $guestList
          arrivalDate: $arrivalDate
          arrivalTime: $arrivalTime
          arriveEarlier: $arriveEarlier
          departureDate: $departureDate
          departureTime: $departureTime
          departLater: $departLater
          needsBed: $needsBed
          remarks: $remarks
          musicStyle: $musicStyle
          musicPreview: $musicPreview
          gearNeed: $gearNeed
          gearBring: $gearBring
          feeEnough: $feeEnough
          callStatus: $callStatus
        }
      ],
      on_conflict: {
        constraint: user_act_pkey,
        update_columns: [
          acceptRules,
          firstTime,
          actName,
          bandSize,
          estFuelCost,
          guestList,
          arrivalDate,
          arrivalTime,
          arriveEarlier,
          departureDate,
          departureTime,
          departLater,
          needsBed,
          remarks,
          musicStyle,
          musicPreview,
          gearNeed,
          gearBring,
          feeEnough,
          callStatus
        ]
      }
    ) {
    returning {
      userId
      eventId
    }
  }
}`;

const UPDATE_USER_CALL_STATUS = gql`
  mutation UpdateUserCallStatus(
    $userId: uuid!,
    $callStatus: String
    ) {
    update_user_event(
      where: {userId: {_eq: $userId}},
      _set: {
        callStatus: $callStatus
      }) {
      affected_rows
      returning {
        userId
        callStatus
        user {
          firstName
        }
      }
    }
  }
`

const UPDATE_ARTIST_CALL_STATUS = gql`
  mutation UpdateArtistCallStatus(
    $userId: uuid!,
    $callStatus: String
    ) {
    update_user_act(
      where: {userId: {_eq: $userId}},
      _set: {
        callStatus: $callStatus
      }) {
      affected_rows
      returning {
        userId
        callStatus
        user {
          firstName
        }
      }
    }
  }
`

const UPDATE_USER_ASSIGNEDTO_STATUS = gql`
  mutation UpdateUserAssignedToStatus(
    $userId: uuid!,
    $assignedTo: String
    ) {
    update_user_event(
      where: {userId: {_eq: $userId}},
      _set: {
        assignedTo: $assignedTo
      }) {
      affected_rows
      returning {
        userId
        assignedTo
        user {
          firstName
        }
      }
    }
  }
`

const DELETE_INSERT_SHIFT_INFO = gql`
  mutation DeleteInsertShiftInfo(
    $captainId: uuid!, 
    $objects: [shift_insert_input]!
    ) {
    delete_shift(where: {captainId: {_eq: $captainId}}) {
      affected_rows
    }
    insert_shift(objects: $objects) {
      affected_rows
    }
  }
`;

const AdminHomePage = () => {

  // TODO: Merge into 1 query
  const { loading, error, data } = useQuery(OVERVIEW_QUERY);

  return (
    <Grid textAlign='center' style={{ height: '100vh', width: '100%' }} verticalAlign='top'>
      <Grid.Column>
        <Message attached>
          <Header as='h2' color='teal' textAlign='center'>
            Overview
          </Header>
          Platform statistics
        </Message>
        <Segment attached>
          <Statistic.Group widths='three'>
            <Statistic>
              <Statistic.Value>{!loading && !error ? data?.users.aggregate.count : 0}</Statistic.Value>
              <Statistic.Label>Users</Statistic.Label>
            </Statistic>

            <Statistic>
              <Statistic.Value>{!loading && !error ? data?.registrations.aggregate.count : 0}</Statistic.Value>
              <Statistic.Label>Registrations</Statistic.Label>
            </Statistic>
            {/* 
            <Statistic>
              <Statistic.Value>{!loading && !error ? data?.confirmed.aggregate.count : 0}</Statistic.Value>
              <Statistic.Label>Confirmed Registrations</Statistic.Label>
            </Statistic> */}

            <Statistic>
              <Statistic.Value><Icon name='ticket' /> {!loading && !error ? data?.soldtickets.aggregate.count : 0}</Statistic.Value>
              <Statistic.Label>Tickets Sold</Statistic.Label>
            </Statistic>
          </Statistic.Group>
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

const AdminUserOverviewPage = () => {

  const [filter, setFilter] = useState({ assignedTo: '', text: '', callAvailable: false, firstTime: false, zookeeper: false, doubleCheck: 'nocheck' })
  const [sortBy, setSortBy] = useState('lastName');

  useEffect(() => {
    let storedFilter = JSON.parse(localStorage.getItem('filter'));
    if (storedFilter) {
      setFilter({ ...storedFilter, ...filter })
    }
    let sortBy = localStorage.getItem('sortBy');
    if (sortBy) {
      setSortBy(sortBy)
    }
    console.log('Refetch Overview!');
    refetch();
  }, [])

  const updateFilter = (e, d) => {
    let newFilter = { ...filter, [d.name]: d.value ? d.value : d.checked }
    if (newFilter.text === undefined) {
      newFilter.text = ''
    }
    if (newFilter.assignedTo === undefined) {
      newFilter.assignedTo = ''
    }
    setFilter(newFilter);
    localStorage.setItem('filter', JSON.stringify(newFilter))
  }

  const clearFilter = () => {
    localStorage.removeItem('filter');
    setFilter({
      assignedTo: '',
      text: '',
      callAvailable: false,
      firstTime: false,
      zookeeper: false,
      hasTicket: false
    })
    localStorage.removeItem('sortBy');
    setSortBy('lastName');
  }

  const sortOptions = [
    { key: 'firstName', value: 'firstName', text: 'First Name' },
    { key: 'firstNamed', value: 'firstNamed', text: 'First Name Desc' },
    { key: 'lastName', value: 'lastName', text: 'Last Name' },
    { key: 'lastNamed', value: 'lastNamed', text: 'Last Name Desc' },
    // { key: 'signedUp', value: 'signedUp', text: 'Signed Up' },
    // { key: 'signedUpd', value: 'signedUpd', text: 'Signed Up Desc' },
  ]

  const updateSortBy = (e, d) => {
    setSortBy(d.value)
    localStorage.setItem('sortBy', d.value)
  }

  const sortByExpression = (a, b) => {
    let dateA = a.events[0]?.signedUp ? new Date(a.events[0].signedUp) : new Date(2000, 1, 1)
    let dateB = b.events[0]?.signedUp ? new Date(b.events[0].signedUp) : new Date(2000, 1, 1)

    switch (sortBy) {
      case 'firstName':
        return a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
      case 'firstNamed':
        return a.firstName.toLowerCase() > b.firstName.toLowerCase() ? -1 : 1
      case 'lastName':
        return a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1
      case 'lastNamed':
        return a.lastName.toLowerCase() > b.lastName.toLowerCase() ? -1 : 1
      case 'signedUp':
        return dateA - dateB
      case 'signedUpd':
        return dateB - dateA
    }
  }

  const [pagination, setPagination] = useState({ page: 0, size: 10 });

  const { loading, error, data, refetch } = useQuery(USER_OVERVIEW_QUERY);

  const [view, setView] = useState('call');
  const handleViewChange = (e, { value }) => setView(value)

  const history = useHistory();
  //if (error) return <Table.Row><Table.Cell>Error :(</Table.Cell></Table.Row>

  if (data)
    console.log(data);

  const statusOptions = [
    { key: 'success', value: 'success', text: 'Success' },
    { key: 'pending', value: 'pending', text: 'Pending' },
    { key: '1try', value: '1try', text: '1st try' },
    { key: '2try', value: '2try', text: '2nd try' },
    { key: '3try', value: '3try', text: '3rd try' },
    { key: 'email', value: 'email', text: 'Sent email' },
    { key: 'lasttry', value: 'lasttry', text: 'Last try' },
  ]

  const assignedToOptions = [
    { key: 'domi', value: 'domi', text: 'Domi' },
    { key: 'team', value: 'team', text: 'Team' },
  ]

  const doubleCheckOptions = [
    { key: 'nocheck', value: 'nocheck', text: 'No Check' },
    { key: 'successCanBuy', value: 'successCanBuy', text: 'Succes / Can Buy Ticket' },
    { key: 'noSuccessCanBuy', value: 'noSuccessCanBuy', text: 'No Succes / Can Buy Ticket' },
    { key: 'successCannotBuy', value: 'successCannotBuy', text: 'Succes / Cannot Buy Ticket' },
  ]

  const doDoubleCheck = (user) => {
    switch (filter.doubleCheck) {
      case 'successCanBuy':
        return user.events[0]?.phase === 2 && user.events[0]?.callStatus === 'success'
      case 'noSuccessCanBuy':
        return user.events[0]?.phase === 2 && user.events[0]?.callStatus !== 'success'
      case 'successCannotBuy':
        return user.events[0]?.phase === 1 && user.events[0]?.callStatus === 'success'
      case 'nocheck':
        return true
    }
  }

  const assignedToFilterOptions = [
    { key: '', value: '', text: 'Any' },
    { key: 'domi', value: 'domi', text: 'Domi' },
    { key: 'team', value: 'team', text: 'Team' },
  ]

  const getCallAvailability = (callMatrix) => {
    if (callMatrix === undefined) {
      return undefined
    }

    let weekDays = {
      0: 'wknd',
      1: 'mo',
      2: 'tu',
      3: 'we',
      4: 'th',
      5: 'fr',
      6: 'wknd'
    }

    let currentDate = new Date();
    let day = weekDays[currentDate.getDay()];
    let dayPeriod;

    if (currentDate.getHours() >= 12 && currentDate.getHours() <= 19) {
      dayPeriod = 1
    } else if (currentDate.getHours() >= 20 && currentDate.getHours() <= 23) {
      dayPeriod = 2
    }

    return callMatrix[day + dayPeriod];
  }

  const handleRowClick = (u) => {
    history.push('/users/' + u.id)
  }

  const [registerUserEvent] = useMutation(UPDATE_USER_CALL_STATUS);

  const handleStatusChange = (event, changeData) => {
    console.log(changeData)
    console.log(changeData.userid)

    registerUserEvent({
      variables: {
        userId: changeData.userid,
        callStatus: changeData.value
      }
    }).then(res => {
      console.log(res);
      if (!res.data) return;
      console.log('Status saved! Updated ' + res?.data.update_user_event.returning[0].user.firstName + ' (' + res?.data.update_user_event.affected_rows + ' rows)');
      toast.success(`Updated CallStatus for ${res?.data.update_user_event.returning[0].user.firstName} to ${changeData.value}`)
      refetch()
    })
  }

  const [setAssignedToStatus] = useMutation(UPDATE_USER_ASSIGNEDTO_STATUS);

  const handleAssignedChange = (event, changeData) => {
    console.log(changeData)
    console.log(changeData.userid)

    setAssignedToStatus({
      variables: {
        userId: changeData.userid,
        assignedTo: changeData.value
      }
    }).then(res => {
      console.log(res);
      if (!res.data) return;
      console.log(`${res.data.update_user_event.returning[0].user.firstName} assigned to ${changeData.value} (${res.data.update_user_event.affected_rows} rows)`);
      toast.success(`${res.data.update_user_event.returning[0].user.firstName} assigned to ${changeData.value}`)
      refetch();
    })
  }

  const [showModal, setShowModal] = useState({});

  const setModalState = (email, show) => {
    setShowModal({ ...showModal, [email]: show });
  };

  const [emailTemplate, setEmailTemplate] = useState('');

  const emailTemplateOptions = [
    { key: 'activateReminder', value: 'activateReminder', text: 'Activation reminder mail' },
    { key: 'missingInformation', value: 'missingInformation', text: 'Missing information mail' },
    { key: 'registerReminder', value: 'registerReminder', text: 'Registration reminder mail' },
    { key: 'threeCalls', value: 'threeCalls', text: 'Three calls mail' },
    { key: 'noContact', value: 'noContact', text: 'No Contact mail' },
  ]

  const handleMailTemplateChange = (e, data) => setEmailTemplate(data.value)

  const sendMail = (body) => {
    console.log(JSON.stringify(body))
    fetch('https://api.lggm.de/mailReminder/', {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(async res => {
        let response = await res.json();
        console.log(response)
        if (response.success) {
          toast.success(`${body.type} mail has been sent to ${body.firstName} (${body.email})!`);
        } else {
          toast.error('Something went wrong, mail has not been sent...')
        }
        refetch();
      })
  }

  return (
    <Grid style={{ height: '100vh', width: '100%' }} verticalAlign='top'>
      <Grid.Column textAlign='center'>
        <Message>
          <Header as='h2' color='teal' textAlign='center'>
            Users
          </Header>
          {data?.user.length ?? '?'} users currently registered on your platform { /* from.pathname */}
        </Message>
        <Form style={{ marginTop: '10px' }}>
          <Form.Group widths='equal'>
            {/* <Form.Field>
              <Form.Select
                name='doubleCheck'
                placeholder='Double Check Filter'
                options={doubleCheckOptions}
                value={filter.doubleCheck}
                onChange={updateFilter} />
            </Form.Field>
            <Form.Field>
              <Form.Select
                // style={{ minWidth: '10em' }}
                name='assignedTo'
                placeholder='Assigned To'
                options={assignedToFilterOptions}
                value={filter.assignedTo}
                onChange={updateFilter} />
            </Form.Field> */}
            <Form.Field width={7}>
              <Input fluid icon='search' name='text' placeholder='Search...' value={filter.text} onChange={updateFilter} />
            </Form.Field>
            <Form.Field width={1}>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Form.Select
                  style={{ flexGrow: 2 }}
                  name='sortBy'
                  placeholder='Sort By'
                  options={sortOptions}
                  value={sortBy}
                  onChange={updateSortBy} />
                <Button onClick={clearFilter}>Clear</Button>
              </div>
            </Form.Field>
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field>
              Found {data?.user
                .filter(u => !(!u.events[0] && u.acts[0]))
                .filter(u => filter.firstTime ? u.events[0]?.firstTime === true : u)
                .filter(u => filter.zookeeper ? u.events[0]?.biggerRole === true : u)
                // .filter(u => filter.assignedTo ? u.events[0]?.assignedTo === filter.assignedTo : u)
                // .filter(u => filter.callAvailable ? getCallAvailability(u.events[0]?.callMatrix) : u)
                .filter(u => filter.hideSuccess ? u.events[0]?.callStatus !== 'success' : u)
                .filter(u => filter.hasTicket ? u.tickets.filter(t => t.transaction.status === 'paid' && t.transaction.amountrefunded === 0).length > 0 : u)
                .filter(u => filter.doubleCheck !== 'nocheck' ? doDoubleCheck(u) : u)
                .filter(u => u.firstName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                  u.lastName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                  u.email?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                  u.phone.includes(filter.text)).length ?? 0} results
            </Form.Field>
            {/* <Form.Field>
              <Radio
                label='Call View'
                name='radioGroup'
                value='call'
                checked={view === 'call'}
                onChange={handleViewChange}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='Plan View'
                name='radioGroup'
                value='plan'
                disabled
                checked={view === 'plan'}
                onChange={handleViewChange}
              />
            </Form.Field> */}
          </Form.Group>
          <Form.Group widths='equal'>
            {/* <Form.Field>
              <Checkbox
                label='Available to Call'
                name='callAvailable'
                checked={filter.callAvailable}
                onChange={updateFilter} />
            </Form.Field> */}
            <Form.Field>
              <Checkbox
                label='First Time'
                name='firstTime'
                checked={filter.firstTime}
                onChange={updateFilter} />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label='Wants BiggerRole'
                name='zookeeper'
                checked={filter.zookeeper}
                onChange={updateFilter} />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label='Has Ticket'
                name='hasTicket'
                checked={filter.hasTicket}
                onChange={updateFilter} />
            </Form.Field>
            {/* <Form.Field>
              <Checkbox
                label='Hide Success'
                name='hideSuccess'
                checked={filter.hideSuccess}
                onChange={updateFilter} />
            </Form.Field> */}
          </Form.Group>
        </Form>
        {view === 'call' ?
          (<Table className="userOverview" celled selectable>
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell><Icon name='calendar' /></Table.HeaderCell> */}
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                {/* <Table.HeaderCell style={{ textAlign: 'center' }}><Icon name='phone' title='Available for call' /></Table.HeaderCell> */}
                {/* <Table.HeaderCell>Status</Table.HeaderCell> */}
                <Table.HeaderCell style={{ textAlign: 'center' }}>1st</Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: 'center' }}><Icon name='bolt' title='Zookeeper' /></Table.HeaderCell>
                {/* <Table.HeaderCell>Assigned</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loading ? (<Table.Row><Table.Cell>Loading...</Table.Cell></Table.Row>) :
                (data?.user
                  .filter(u => !(!u.events[0] && u.acts[0]))
                  .filter(u => filter.firstTime ? u.events[0]?.firstTime === true : u)
                  .filter(u => filter.zookeeper ? u.events[0]?.biggerRole === true : u)
                  // .filter(u => filter.assignedTo ? u.events[0]?.assignedTo === filter.assignedTo : u)
                  // .filter(u => filter.callAvailable ? getCallAvailability(u.events[0]?.callMatrix) : u)
                  .filter(u => filter.hideSuccess ? u.events[0]?.callStatus !== 'success' : u)
                  .filter(u => filter.hasTicket ? u.tickets.filter(t => t.transaction.status === 'paid' && t.transaction.amountrefunded === 0).length > 0 : u)
                  .filter(u => filter.doubleCheck !== 'nocheck' ? doDoubleCheck(u) : u)
                  .filter(u => u.firstName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                    u.lastName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                    u.email?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                    u.phone.includes(filter.text))
                  .sort((a, b) => sortByExpression(a, b))
                  .map(u => (
                    <Table.Row key={u.email} negative={!u.active} positive={u.events.length > 0 && u.events.length !== 0}>
                      {/* <Table.Cell>{u.events.length > 0 && u.events[0].signedUp ? <Icon name='checkmark' /> : null}</Table.Cell> history.push('/users/' + u.id) */}
                      <Table.Cell onClick={() => handleRowClick(u)} style={{ textAlign: 'center' }}>{u.tickets.filter(t => t.transaction.status === 'paid' && t.transaction.amountrefunded === 0).length > 0 ? <Icon style={{ margin: 0 }} name='ticket' title='has a ticket' /> : ''}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.volunteerShifts.length > 0 ? 'V' : u.captainShifts.length > 0 ? 'C' : ''}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.firstName}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.lastName}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.email}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.phone}</Table.Cell>
                      {/* <Table.Cell onClick={() => handleRowClick(u)} style={{ padding: 0, textAlign: 'center', fontSize: '20px' }}>{u.events[0] ? getCallAvailability(u.events[0]?.callMatrix) ? <Icon name='check' style={{ color: 'green' }} /> : <Icon name='x' style={{ color: 'red' }} /> : null}</Table.Cell> */}
                      <Table.Cell onClick={() => handleRowClick(u)} style={{ padding: 0, textAlign: 'center', fontSize: '20px' }}>{u.events.length > 0 && u.events[0].firstTime ? <Icon name='checkmark' style={{ color: 'green' }} /> : null}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)} style={{ padding: 0, textAlign: 'center', fontSize: '20px' }}>{u.events.length > 0 && u.events[0].biggerRole ? <Icon name='checkmark' style={{ color: 'green' }} /> : null}</Table.Cell>
                      {/* <Table.Cell style={{ padding: 0, width: '1px' }}>{u.events.length > 0 && u.events.length !== 0 ? <Select style={{ minWidth: '10em' }} placeholder='Assigned To' options={assignedToOptions} value={u.events[0]?.assignedTo} userid={u.id} onChange={handleAssignedChange} /> : null}</Table.Cell>
                      <Table.Cell style={{ padding: 0, width: '1px' }}>{u.events.length > 0 && u.events.length !== 0 ? <Select style={{ minWidth: '10em' }} placeholder='Select Status' options={statusOptions} value={u.events[0]?.callStatus} userid={u.id} onChange={handleStatusChange} /> : null}</Table.Cell>
                      <Table.Cell style={{ padding: 0, width: '1px' }}>
                        <Modal size={'tiny'} trigger={<Button icon primary onClick={(event) => { event.stopPropagation(); setModalState(u.email, true) }}><Icon name='mail' /></Button>} closeOnDimmerClick={true} open={showModal[u.email]}
                          onClose={() => setModalState(u.email, false)}>
                          <Modal.Header>Email to {u.firstName} {u.lastName}</Modal.Header>
                          <Modal.Content>
                            <Modal.Description style={{ textAlign: 'center' }}>
                              {/* <Header>Choose email template</Header> 
                              Emails sent earlier
                              <List>
                                {u.emailreminders.length !== 0 ? u.emailreminders.map(r => <Item key={r.timestamp}>{new Date(r.timestamp).toLocaleDateString()} - {r.type}</Item>) : <Item>No mails</Item>}
                              </List>
                              <br />
                              Email to send now
                              <Form.Select
                                name='emailTemplate'
                                value={emailTemplate}
                                onChange={handleMailTemplateChange}
                                placeholder='Email template to send'
                                options={emailTemplateOptions} />
                            </Modal.Description>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button color='red' onClick={() => setModalState(u.email, false)}>
                              <Icon name='cancel' /> Cancel
                            </Button>
                            <Button color='green' onClick={() => { sendMail({ firstName: u.firstName, email: u.email, type: emailTemplate }); setModalState(u.email, false) }}>
                              <Icon name='checkmark' /> Send
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      </Table.Cell> */}
                    </Table.Row>
                  )))}
            </Table.Body>
          </Table>) :
          (<Table className="userOverview" celled selectable>
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell><Icon name='calendar' /></Table.HeaderCell> */}
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Arrival</Table.HeaderCell>
                <Table.HeaderCell>Departure</Table.HeaderCell>
                {/* <Table.HeaderCell>Status</Table.HeaderCell> */}
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.HeaderCell>Shift</Table.HeaderCell>
                <Table.HeaderCell>Buddy</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loading ? (<Table.Row><Table.Cell>Loading...</Table.Cell></Table.Row>) :
                (data?.user
                  .filter(u => u.firstName?.toLowerCase().includes(filter?.toLowerCase()) ||
                    u.lastName?.toLowerCase().includes(filter?.toLowerCase()) ||
                    u.email?.toLowerCase().includes(filter?.toLowerCase()) ||
                    u.phone.includes(filter))
                  .map(u => (
                    <Table.Row key={u.email} negative={!u.active} positive={u.events.length > 0 && u.events[0].signedUp} onClick={() => history.push('/users/' + u.id)}>
                      {/* <Table.Cell>{u.events.length > 0 && u.events[0].signedUp ? <Icon name='checkmark' /> : null}</Table.Cell> */}
                      <Table.Cell>{u.firstName}</Table.Cell>
                      <Table.Cell>{u.lastName}</Table.Cell>
                      <Table.Cell>{u.events.length > 0 ? u.events[0].arrivalDate + ' ' + u.events[0].arrivalTime : null}</Table.Cell>
                      <Table.Cell>{u.events.length > 0 ? u.events[0].departureDate + ' ' + u.events[0].departureTime : null}</Table.Cell>
                      <Table.Cell>{u.events.length > 0 ? u.events[0].preferredRole : null}</Table.Cell>
                      <Table.Cell>{u.events.length > 0 ? u.events[0].preferredShift : null}</Table.Cell>
                      <Table.Cell>{u.events.length > 0 ? u.events[0].preferredBuddy : null}</Table.Cell>
                      <Table.Cell style={{ padding: 0 }}> <Select placeholder='Select Status' options={statusOptions} /></Table.Cell>
                    </Table.Row>
                  )))}
            </Table.Body>
          </Table>)}
        {/* <Message attached='bottom' info>
        New to us? <Link to='/register'>Sign Up</Link>
      </Message> */}
      </Grid.Column>
    </Grid>
  )
}

const AdminCaptainOverviewPage = () => {

  const [filter, setFilter] = useState({ assignedTo: '', text: '', callAvailable: false, firstTime: false, zookeeper: false, doubleCheck: 'nocheck' })
  const [sortBy, setSortBy] = useState('lastName');

  useEffect(() => {
    let storedFilter = JSON.parse(localStorage.getItem('filter'));
    if (storedFilter) {
      setFilter({ ...storedFilter, ...filter })
    }
    let sortBy = localStorage.getItem('sortBy');
    if (sortBy) {
      setSortBy(sortBy)
    }
    console.log('Refetch Overview!');
    refetch();
  }, [])

  const updateFilter = (e, d) => {
    let newFilter = { ...filter, [d.name]: d.value ? d.value : d.checked }
    if (newFilter.text === undefined) {
      newFilter.text = ''
    }
    if (newFilter.assignedTo === undefined) {
      newFilter.assignedTo = ''
    }
    setFilter(newFilter);
    localStorage.setItem('filter', JSON.stringify(newFilter))
  }

  const clearFilter = () => {
    localStorage.removeItem('filter');
    setFilter({
      assignedTo: '',
      text: '',
      callAvailable: false,
      firstTime: false,
      zookeeper: false
    })
    localStorage.removeItem('sortBy');
    setSortBy('lastName');
  }

  const sortOptions = [
    { key: 'firstName', value: 'firstName', text: 'First Name' },
    { key: 'firstNamed', value: 'firstNamed', text: 'First Name Desc' },
    { key: 'lastName', value: 'lastName', text: 'Last Name' },
    { key: 'lastNamed', value: 'lastNamed', text: 'Last Name Desc' },
    { key: 'signedUp', value: 'signedUp', text: 'Signed Up' },
    { key: 'signedUpd', value: 'signedUpd', text: 'Signed Up Desc' },
  ]

  const updateSortBy = (e, d) => {
    setSortBy(d.value)
    localStorage.setItem('sortBy', d.value)
  }

  const sortByExpression = (a, b) => {
    let dateA = a.events[0]?.signedUp ? new Date(a.events[0].signedUp) : new Date(2000, 1, 1)
    let dateB = b.events[0]?.signedUp ? new Date(b.events[0].signedUp) : new Date(2000, 1, 1)

    switch (sortBy) {
      case 'firstName':
        return a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
      case 'firstNamed':
        return a.firstName.toLowerCase() > b.firstName.toLowerCase() ? -1 : 1
      case 'lastName':
        return a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1
      case 'lastNamed':
        return a.lastName.toLowerCase() > b.lastName.toLowerCase() ? -1 : 1
      case 'signedUp':
        return dateA - dateB
      case 'signedUpd':
        return dateB - dateA
    }
  }

  const [pagination, setPagination] = useState({ page: 0, size: 10 });

  const { loading, error, data, refetch } = useQuery(USER_OVERVIEW_QUERY);

  const [view, setView] = useState('call');
  const handleViewChange = (e, { value }) => setView(value)

  const history = useHistory();
  //if (error) return <Table.Row><Table.Cell>Error :(</Table.Cell></Table.Row>

  if (data)
    console.log(data);

  const statusOptions = [
    { key: 'success', value: 'success', text: 'Success' },
    { key: 'pending', value: 'pending', text: 'Pending' },
    { key: '1try', value: '1try', text: '1st try' },
    { key: '2try', value: '2try', text: '2nd try' },
    { key: '3try', value: '3try', text: '3rd try' },
    { key: 'email', value: 'email', text: 'Sent email' },
    { key: 'lasttry', value: 'lasttry', text: 'Last try' },
  ]

  const assignedToOptions = [
    { key: 'domi', value: 'domi', text: 'Domi' },
    { key: 'team', value: 'team', text: 'Team' },
  ]

  const doubleCheckOptions = [
    { key: 'nocheck', value: 'nocheck', text: 'No Check' },
    { key: 'successCanBuy', value: 'successCanBuy', text: 'Succes / Can Buy Ticket' },
    { key: 'noSuccessCanBuy', value: 'noSuccessCanBuy', text: 'No Succes / Can Buy Ticket' },
    { key: 'successCannotBuy', value: 'successCannotBuy', text: 'Succes / Cannot Buy Ticket' },
  ]

  const doDoubleCheck = (user) => {
    switch (filter.doubleCheck) {
      case 'successCanBuy':
        return user.events[0]?.phase === 2 && user.events[0]?.callStatus === 'success'
      case 'noSuccessCanBuy':
        return user.events[0]?.phase === 2 && user.events[0]?.callStatus !== 'success'
      case 'successCannotBuy':
        return user.events[0]?.phase === 1 && user.events[0]?.callStatus === 'success'
      case 'nocheck':
        return true
    }
  }

  const assignedToFilterOptions = [
    { key: '', value: '', text: 'Any' },
    { key: 'domi', value: 'domi', text: 'Domi' },
    { key: 'team', value: 'team', text: 'Team' },
  ]

  const getCallAvailability = (callMatrix) => {
    if (callMatrix === undefined) {
      return undefined
    }

    let weekDays = {
      0: 'wknd',
      1: 'mo',
      2: 'tu',
      3: 'we',
      4: 'th',
      5: 'fr',
      6: 'wknd'
    }

    let currentDate = new Date();
    let day = weekDays[currentDate.getDay()];
    let dayPeriod;

    if (currentDate.getHours() >= 12 && currentDate.getHours() <= 19) {
      dayPeriod = 1
    } else if (currentDate.getHours() >= 20 && currentDate.getHours() <= 23) {
      dayPeriod = 2
    }

    return callMatrix[day + dayPeriod];
  }

  const handleRowClick = (u) => {
    history.push('/users/' + u.id)
  }

  const [registerUserEvent] = useMutation(UPDATE_USER_CALL_STATUS);

  const handleStatusChange = (event, changeData) => {
    console.log(changeData)
    console.log(changeData.userid)

    registerUserEvent({
      variables: {
        userId: changeData.userid,
        callStatus: changeData.value
      }
    }).then(res => {
      console.log(res);
      if (!res.data) return;
      console.log('Status saved! Updated ' + res?.data.update_user_event.returning[0].user.firstName + ' (' + res?.data.update_user_event.affected_rows + ' rows)');
      toast.success(`Updated CallStatus for ${res?.data.update_user_event.returning[0].user.firstName} to ${changeData.value}`)
      refetch()
    })
  }

  const [setAssignedToStatus] = useMutation(UPDATE_USER_ASSIGNEDTO_STATUS);

  const handleAssignedChange = (event, changeData) => {
    console.log(changeData)
    console.log(changeData.userid)

    setAssignedToStatus({
      variables: {
        userId: changeData.userid,
        assignedTo: changeData.value
      }
    }).then(res => {
      console.log(res);
      if (!res.data) return;
      console.log(`${res.data.update_user_event.returning[0].user.firstName} assigned to ${changeData.value} (${res.data.update_user_event.affected_rows} rows)`);
      toast.success(`${res.data.update_user_event.returning[0].user.firstName} assigned to ${changeData.value}`)
      refetch();
    })
  }

  const [showModal, setShowModal] = useState({});

  const setModalState = (email, show) => {
    setShowModal({ ...showModal, [email]: show });
  };

  const [emailTemplate, setEmailTemplate] = useState('');

  const emailTemplateOptions = [
    { key: 'activateReminder', value: 'activateReminder', text: 'Activation reminder mail' },
    { key: 'missingInformation', value: 'missingInformation', text: 'Missing information mail' },
    { key: 'registerReminder', value: 'registerReminder', text: 'Registration reminder mail' },
    { key: 'threeCalls', value: 'threeCalls', text: 'Three calls mail' },
    { key: 'noContact', value: 'noContact', text: 'No Contact mail' },
  ]

  const handleMailTemplateChange = (e, data) => setEmailTemplate(data.value)

  const sendMail = (body) => {
    console.log(JSON.stringify(body))
    fetch('https://api.lggm.de/mailReminder/', {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(async res => {
        let response = await res.json();
        console.log(response)
        if (response.success) {
          toast.success(`${body.type} mail has been sent to ${body.firstName} (${body.email})!`);
        } else {
          toast.error('Something went wrong, mail has not been sent...')
        }
        refetch();
      })
  }

  return (
    <Grid style={{ height: '100vh', width: '100%' }} verticalAlign='top'>
      <Grid.Column textAlign='center'>
        <Message>
          <Header as='h2' color='teal' textAlign='center'>
            User
          </Header>
          {data?.user.length} users currently registered on your platform { /* from.pathname */}
        </Message>
        <Form style={{ marginTop: '10px' }}>
          <Form.Group widths='equal'>
            {/* <Form.Field>
              <Form.Select
                name='doubleCheck'
                placeholder='Double Check Filter'
                options={doubleCheckOptions}
                value={filter.doubleCheck}
                onChange={updateFilter} />
            </Form.Field>
            <Form.Field>
              <Form.Select
                // style={{ minWidth: '10em' }}
                name='assignedTo'
                placeholder='Assigned To'
                options={assignedToFilterOptions}
                value={filter.assignedTo}
                onChange={updateFilter} />
            </Form.Field> */}
            <Form.Field>
              <Input fluid icon='search' name='text' placeholder='Search...' value={filter.text} onChange={updateFilter} />
            </Form.Field>
            <Form.Field>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Form.Select
                  style={{ flexGrow: 2 }}
                  name='sortBy'
                  placeholder='Sort By'
                  options={sortOptions}
                  value={sortBy}
                  onChange={updateSortBy} />
                <Button onClick={clearFilter}>Clear</Button>
              </div>
            </Form.Field>
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field>
              Found {data?.user
                .filter(u => !(!u.events[0] && u.acts[0]))
                .filter(u => filter.firstTime ? u.events[0]?.firstTime === true : u)
                .filter(u => filter.zookeeper ? u.events[0]?.biggerRole === true : u)
                .filter(u => filter.assignedTo ? u.events[0]?.assignedTo === filter.assignedTo : u)
                .filter(u => filter.callAvailable ? getCallAvailability(u.events[0]?.callMatrix) : u)
                .filter(u => filter.hideSuccess ? u.events[0]?.callStatus !== 'success' : u)
                .filter(u => filter.doubleCheck !== 'nocheck' ? doDoubleCheck(u) : u)
                .filter(u => u.firstName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                  u.lastName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                  u.email?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                  u.phone.includes(filter.text)).length ?? 0} results
            </Form.Field>
            <Form.Field>
              <Radio
                label='Call View'
                name='radioGroup'
                value='call'
                checked={view === 'call'}
                onChange={handleViewChange}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='Plan View'
                name='radioGroup'
                value='plan'
                disabled
                checked={view === 'plan'}
                onChange={handleViewChange}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field>
              <Checkbox
                label='Available to Call'
                name='callAvailable'
                checked={filter.callAvailable}
                onChange={updateFilter} />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label='First Time'
                name='firstTime'
                checked={filter.firstTime}
                onChange={updateFilter} />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label='Wants Zookeeper'
                name='zookeeper'
                checked={filter.zookeeper}
                onChange={updateFilter} />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label='Hide Success'
                name='hideSuccess'
                checked={filter.hideSuccess}
                onChange={updateFilter} />
            </Form.Field>
          </Form.Group>
        </Form>
        {view === 'call' ?
          (<Table className="userOverview" celled selectable>
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell><Icon name='calendar' /></Table.HeaderCell> */}
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                {/* <Table.HeaderCell style={{ textAlign: 'center' }}><Icon name='phone' title='Available for call' /></Table.HeaderCell> */}
                {/* <Table.HeaderCell>Status</Table.HeaderCell> */}
                <Table.HeaderCell style={{ textAlign: 'center' }}>1st</Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: 'center' }}><Icon name='bolt' title='Zookeeper' /></Table.HeaderCell>
                {/* <Table.HeaderCell>Assigned</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell> */}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loading ? (<Table.Row><Table.Cell>Loading...</Table.Cell></Table.Row>) :
                (data?.user
                  .filter(u => !(!u.events[0] && u.acts[0]))
                  .filter(u => filter.firstTime ? u.events[0]?.firstTime === true : u)
                  .filter(u => filter.zookeeper ? u.events[0]?.biggerRole === true : u)
                  .filter(u => filter.assignedTo ? u.events[0]?.assignedTo === filter.assignedTo : u)
                  .filter(u => filter.callAvailable ? getCallAvailability(u.events[0]?.callMatrix) : u)
                  .filter(u => filter.hideSuccess ? u.events[0]?.callStatus !== 'success' : u)
                  .filter(u => filter.doubleCheck !== 'nocheck' ? doDoubleCheck(u) : u)
                  .filter(u => u.firstName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                    u.lastName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                    u.email?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                    u.phone.includes(filter.text))
                  .sort((a, b) => sortByExpression(a, b))
                  .map(u => (
                    <Table.Row key={u.email} negative={!u.active} positive={u.events.length > 0 && u.events.length !== 0}>
                      {/* <Table.Cell>{u.events.length > 0 && u.events[0].signedUp ? <Icon name='checkmark' /> : null}</Table.Cell> history.push('/users/' + u.id) */}
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.firstName}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.lastName}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.email}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.phone}</Table.Cell>
                      {/* <Table.Cell onClick={() => handleRowClick(u)} style={{ padding: 0, textAlign: 'center', fontSize: '20px' }}>{u.events[0] ? getCallAvailability(u.events[0]?.callMatrix) ? <Icon name='check' style={{ color: 'green' }} /> : <Icon name='x' style={{ color: 'red' }} /> : null}</Table.Cell> */}
                      <Table.Cell onClick={() => handleRowClick(u)} style={{ padding: 0, textAlign: 'center', fontSize: '20px' }}>{u.events.length > 0 && u.events[0].firstTime ? <Icon name='checkmark' style={{ color: 'green' }} /> : null}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)} style={{ padding: 0, textAlign: 'center', fontSize: '20px' }}>{u.events.length > 0 && u.events[0].biggerRole ? <Icon name='checkmark' style={{ color: 'green' }} /> : null}</Table.Cell>
                      {/* <Table.Cell style={{ padding: 0, width: '1px' }}>{u.events.length > 0 && u.events.length !== 0 ? <Select style={{ minWidth: '10em' }} placeholder='Assigned To' options={assignedToOptions} value={u.events[0]?.assignedTo} userid={u.id} onChange={handleAssignedChange} /> : null}</Table.Cell>
                      <Table.Cell style={{ padding: 0, width: '1px' }}>{u.events.length > 0 && u.events.length !== 0 ? <Select style={{ minWidth: '10em' }} placeholder='Select Status' options={statusOptions} value={u.events[0]?.callStatus} userid={u.id} onChange={handleStatusChange} /> : null}</Table.Cell>
                      <Table.Cell style={{ padding: 0, width: '1px' }}>
                        <Modal size={'tiny'} trigger={<Button icon primary onClick={(event) => { event.stopPropagation(); setModalState(u.email, true) }}><Icon name='mail' /></Button>} closeOnDimmerClick={true} open={showModal[u.email]}
                          onClose={() => setModalState(u.email, false)}>
                          <Modal.Header>Email to {u.firstName} {u.lastName}</Modal.Header>
                          <Modal.Content>
                            <Modal.Description style={{ textAlign: 'center' }}>
                              {/* <Header>Choose email template</Header>
                              Emails sent earlier
                              <List>
                                {u.emailreminders.length !== 0 ? u.emailreminders.map(r => <Item key={r.timestamp}>{new Date(r.timestamp).toLocaleDateString()} - {r.type}</Item>) : <Item>No mails</Item>}
                              </List>
                              <br />
                              Email to send now
                              <Form.Select
                                name='emailTemplate'
                                value={emailTemplate}
                                onChange={handleMailTemplateChange}
                                placeholder='Email template to send'
                                options={emailTemplateOptions} />
                            </Modal.Description>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button color='red' onClick={() => setModalState(u.email, false)}>
                              <Icon name='cancel' /> Cancel
                            </Button>
                            <Button color='green' onClick={() => { sendMail({ firstName: u.firstName, email: u.email, type: emailTemplate }); setModalState(u.email, false) }}>
                              <Icon name='checkmark' /> Send
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      </Table.Cell> */}
                    </Table.Row>
                  )))}
            </Table.Body>
          </Table>) :
          (<Table className="userOverview" celled selectable>
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell><Icon name='calendar' /></Table.HeaderCell> */}
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Arrival</Table.HeaderCell>
                <Table.HeaderCell>Departure</Table.HeaderCell>
                {/* <Table.HeaderCell>Status</Table.HeaderCell> */}
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.HeaderCell>Shift</Table.HeaderCell>
                <Table.HeaderCell>Buddy</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loading ? (<Table.Row><Table.Cell>Loading...</Table.Cell></Table.Row>) :
                (data?.user
                  .filter(u => u.firstName?.toLowerCase().includes(filter?.toLowerCase()) ||
                    u.lastName?.toLowerCase().includes(filter?.toLowerCase()) ||
                    u.email?.toLowerCase().includes(filter?.toLowerCase()) ||
                    u.phone.includes(filter))
                  .map(u => (
                    <Table.Row key={u.email} negative={!u.active} positive={u.events.length > 0 && u.events[0].signedUp} onClick={() => history.push('/users/' + u.id)}>
                      {/* <Table.Cell>{u.events.length > 0 && u.events[0].signedUp ? <Icon name='checkmark' /> : null}</Table.Cell> */}
                      <Table.Cell>{u.firstName}</Table.Cell>
                      <Table.Cell>{u.lastName}</Table.Cell>
                      <Table.Cell>{u.events.length > 0 ? u.events[0].arrivalDate + ' ' + u.events[0].arrivalTime : null}</Table.Cell>
                      <Table.Cell>{u.events.length > 0 ? u.events[0].departureDate + ' ' + u.events[0].departureTime : null}</Table.Cell>
                      <Table.Cell>{u.events.length > 0 ? u.events[0].preferredRole : null}</Table.Cell>
                      <Table.Cell>{u.events.length > 0 ? u.events[0].preferredShift : null}</Table.Cell>
                      <Table.Cell>{u.events.length > 0 ? u.events[0].preferredBuddy : null}</Table.Cell>
                      <Table.Cell style={{ padding: 0 }}> <Select placeholder='Select Status' options={statusOptions} /></Table.Cell>
                    </Table.Row>
                  )))}
            </Table.Body>
          </Table>)}
        {/* <Message attached='bottom' info>
        New to us? <Link to='/register'>Sign Up</Link>
      </Message> */}
      </Grid.Column>
    </Grid>
  )
}

const AdminArtistOverviewPage = () => {

  const [filter, setFilter] = useState({ text: '', firstTime: false })
  const [sortBy, setSortBy] = useState('lastName');

  useEffect(() => {
    let storedFilter = JSON.parse(localStorage.getItem('filter'));
    if (storedFilter) {
      setFilter({ ...storedFilter, ...filter })
    }
    let sortBy = localStorage.getItem('sortBy');
    if (sortBy) {
      setSortBy(sortBy)
    }
    console.log('Refetch Overview!');
    refetch();
  }, [])

  const updateFilter = (e, d) => {
    let newFilter = { ...filter, [d.name]: d.value ? d.value : d.checked }
    if (newFilter.text === undefined) {
      newFilter.text = ''
    }
    setFilter(newFilter);
    localStorage.setItem('filter', JSON.stringify(newFilter))
  }

  const clearFilter = () => {
    localStorage.removeItem('filter');
    setFilter({
      text: '',
      firstTime: false
    })
    localStorage.removeItem('sortBy');
    setSortBy('lastName');
  }

  const sortOptions = [
    { key: 'firstName', value: 'firstName', text: 'First Name' },
    { key: 'firstNamed', value: 'firstNamed', text: 'First Name Desc' },
    { key: 'lastName', value: 'lastName', text: 'Last Name' },
    { key: 'lastNamed', value: 'lastNamed', text: 'Last Name Desc' },
    { key: 'signedUp', value: 'signedUp', text: 'Signed Up' },
    { key: 'signedUpd', value: 'signedUpd', text: 'Signed Up Desc' },
  ]

  const artistOptions = [
    { key: 'all', value: 'all', text: 'All' },
    { key: 'band', value: 'band', text: 'Band' },
    { key: 'dj', value: 'dj', text: 'DJ' }
  ]

  const updateSortBy = (e, d) => {
    setSortBy(d.value)
    localStorage.setItem('sortBy', d.value)
  }

  const sortByExpression = (a, b) => {
    let dateA = a.acts[0]?.created_at ? new Date(a.acts[0].created_at) : new Date(2000, 1, 1)
    let dateB = b.acts[0]?.created_at ? new Date(b.acts[0].created_at) : new Date(2000, 1, 1)

    switch (sortBy) {
      case 'firstName':
        return a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1
      case 'firstNamed':
        return a.firstName.toLowerCase() > b.firstName.toLowerCase() ? -1 : 1
      case 'lastName':
        return a.lastName.toLowerCase() > b.lastName.toLowerCase() ? 1 : -1
      case 'lastNamed':
        return a.lastName.toLowerCase() > b.lastName.toLowerCase() ? -1 : 1
      case 'signedUp':
        return dateA - dateB
      case 'signedUpd':
        return dateB - dateA
    }
  }

  const [pagination, setPagination] = useState({ page: 0, size: 10 });

  const { loading, error, data, refetch } = useQuery(USER_OVERVIEW_QUERY);

  const [view, setView] = useState('call');
  const handleViewChange = (e, { value }) => setView(value)

  const history = useHistory();
  //if (error) return <Table.Row><Table.Cell>Error :(</Table.Cell></Table.Row>

  if (data)
    console.log(data);

  const statusOptions = [
    { key: 'success', value: 'success', text: 'Success' },
    { key: 'pending', value: 'pending', text: 'Pending' },
    { key: '1try', value: '1try', text: '1st try' },
    { key: '2try', value: '2try', text: '2nd try' },
    { key: '3try', value: '3try', text: '3rd try' },
    { key: 'email', value: 'email', text: 'Sent email' },
    { key: 'lasttry', value: 'lasttry', text: 'Last try' },
  ]

  const handleRowClick = (u) => {
    history.push('/users/' + u.id)
  }

  const [registerUserAct] = useMutation(UPDATE_ARTIST_CALL_STATUS);

  const handleStatusChange = (event, changeData) => {
    console.log(changeData)
    console.log(changeData.userid)

    registerUserAct({
      variables: {
        userId: changeData.userid,
        callStatus: changeData.value
      }
    }).then(res => {
      console.log(res);
      if (!res.data) return;
      console.log('Status saved! Updated ' + res?.data.update_user_act.returning[0].user.firstName + ' (' + res?.data.update_user_act.affected_rows + ' rows)');
      toast.success(`Updated CallStatus for ${res?.data.update_user_act.returning[0].user.firstName} to ${changeData.value}`)
      refetch()
    })
  }

  const [showModal, setShowModal] = useState({});

  const setModalState = (email, show) => {
    setShowModal({ ...showModal, [email]: show });
  };

  const [emailTemplate, setEmailTemplate] = useState('');

  const emailTemplateOptions = [
    { key: 'activateReminder', value: 'activateReminder', text: 'Activation reminder mail' },
    { key: 'missingInformation', value: 'missingInformation', text: 'Missing information mail' },
    { key: 'registerReminder', value: 'registerReminder', text: 'Registration reminder mail' },
    { key: 'threeCalls', value: 'threeCalls', text: 'Three calls mail' },
    { key: 'noContact', value: 'noContact', text: 'No Contact mail' },
  ]

  const handleMailTemplateChange = (e, data) => setEmailTemplate(data.value)

  const sendMail = (body) => {
    console.log(JSON.stringify(body))
    fetch('https://api.lggm.de/mailReminder/', {
      method: 'post',
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(async res => {
        let response = await res.json();
        console.log(response)
        if (response.success) {
          toast.success(`${body.type} mail has been sent to ${body.firstName} (${body.email})!`);
        } else {
          toast.error('Something went wrong, mail has not been sent...')
        }
        refetch();
      })
  }

  return (
    <Grid style={{ height: '100vh', width: '100%' }} verticalAlign='top'>
      <Grid.Column textAlign='center'>
        <Message>
          <Header as='h2' color='teal' textAlign='center'>
            Artists
          </Header>
          {data?.user.filter(u => u.acts[0]).length} artists currently registered on your platform { /* from.pathname */}
        </Message>
        <Form style={{ marginTop: '10px' }}>
          <Form.Group widths='equal'>
            <Form.Field>
              <Form.Select
                // style={{ minWidth: '10em' }}
                name='artistType'
                placeholder='Artist Type'
                options={artistOptions}
                value={filter.artistType}
                onChange={updateFilter} />
            </Form.Field>
            <Form.Field>
              <Input fluid icon='search' name='text' placeholder='Search...' value={filter.text} onChange={updateFilter} />
            </Form.Field>
            <Form.Field>
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Form.Select
                  style={{ flexGrow: 2 }}
                  name='sortBy'
                  placeholder='Sort By'
                  options={sortOptions}
                  value={sortBy}
                  onChange={updateSortBy} />
                <Button onClick={clearFilter}>Clear</Button>
              </div>
            </Form.Field>
          </Form.Group>
          <Form.Group widths='equal'>
            <Form.Field>
              Found {data?.user
                .filter(u => u.acts[0])
                .filter(u => filter.artistType === 'band' ? u.acts[0].bandSize > 0 : filter.artistType === 'dj' ? u.acts[0].bandSize === 0 : u)
                .filter(u => filter.firstTime ? u.acts[0]?.firstTime === true : u)
                .filter(u => filter.hideSuccess ? u.acts[0]?.callStatus !== 'success' : u)
                .filter(u => u.firstName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                  u.lastName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                  u.email?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                  u.phone.includes(filter.text).length ||
                  u.acts[0].actName?.toLowerCase().includes(filter.text?.toLowerCase()).length ||
                  u.acts[0].musicStyle?.toLowerCase().includes(filter.text?.toLowerCase())).length ?? 0} results
            </Form.Field>
            <Form.Field>
              <Checkbox
                label='First Time'
                name='firstTime'
                checked={filter.firstTime}
                onChange={updateFilter} />
            </Form.Field>
            <Form.Field>
              <Checkbox
                label='Hide Success'
                name='hideSuccess'
                checked={filter.hideSuccess}
                onChange={updateFilter} />
            </Form.Field>
            <Form.Field>
              <Radio
                label='Call View'
                name='radioGroup'
                value='call'
                checked={view === 'call'}
                onChange={handleViewChange}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='Plan View'
                name='radioGroup'
                value='plan'
                disabled
                checked={view === 'plan'}
                onChange={handleViewChange}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        {view === 'call' ?
          (<Table className="userOverview" celled selectable>
            <Table.Header>
              <Table.Row>
                {/* <Table.HeaderCell><Icon name='calendar' /></Table.HeaderCell> */}
                <Table.HeaderCell>First Name</Table.HeaderCell>
                <Table.HeaderCell>Last Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
                <Table.HeaderCell>Act Name</Table.HeaderCell>
                <Table.HeaderCell>Size</Table.HeaderCell>
                <Table.HeaderCell>Style</Table.HeaderCell>
                <Table.HeaderCell style={{ textAlign: 'center' }}>1st</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {loading ? (<Table.Row><Table.Cell>Loading...</Table.Cell></Table.Row>) :
                (data?.user
                  .filter(u => u.acts[0])
                  .filter(u => filter.artistType === 'band' ? u.acts[0].bandSize > 0 : filter.artistType === 'dj' ? u.acts[0].bandSize === 0 : u)
                  .filter(u => filter.firstTime ? u.acts[0]?.firstTime === true : u)
                  .filter(u => filter.hideSuccess ? u.acts[0]?.callStatus !== 'success' : u)
                  .filter(u => u.firstName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                    u.lastName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                    u.email?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                    u.phone.includes(filter.text) || u.acts[0].actName?.toLowerCase().includes(filter.text?.toLowerCase()) ||
                    u.acts[0].musicStyle?.toLowerCase().includes(filter.text?.toLowerCase()))
                  .sort((a, b) => sortByExpression(a, b))
                  .map(u => (
                    <Table.Row key={u.email} positive={u.events.length > 0 && u.events.length !== 0}>
                      {/* <Table.Cell>{u.events.length > 0 && u.events[0].signedUp ? <Icon name='checkmark' /> : null}</Table.Cell> history.push('/users/' + u.id) */}
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.firstName}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.lastName}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.email}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.phone}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.acts.length > 0 && u.acts[0].actName}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.acts.length > 0 && u.acts[0].bandSize < 1 ? 'DJ' : u.acts[0].bandSize}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)}>{u.acts.length > 0 && u.acts[0].musicStyle}</Table.Cell>
                      <Table.Cell onClick={() => handleRowClick(u)} style={{ padding: 0, textAlign: 'center', fontSize: '20px' }}>{u.acts.length > 0 && u.acts[0].firstTime ? <Icon name='checkmark' style={{ color: 'green' }} /> : null}</Table.Cell>
                      <Table.Cell style={{ padding: 0, width: '1px' }}>{u.acts.length > 0 && u.acts.length !== 0 ? <Select style={{ minWidth: '10em' }} placeholder='Select Status' options={statusOptions} value={u.acts[0].callStatus} userid={u.id} onChange={handleStatusChange} /> : null}</Table.Cell>
                      <Table.Cell style={{ padding: 0, width: '1px' }}>
                        <Modal size={'tiny'} trigger={<Button icon primary onClick={(event) => { event.stopPropagation(); setModalState(u.email, true) }}><Icon name='mail' /></Button>} closeOnDimmerClick={true} open={showModal[u.email]}
                          onClose={() => setModalState(u.email, false)}>
                          <Modal.Header>Email to {u.firstName} {u.lastName}</Modal.Header>
                          <Modal.Content>
                            <Modal.Description style={{ textAlign: 'center' }}>
                              {/* <Header>Choose email template</Header> */}
                              Emails sent earlier
                              <List>
                                {u.emailreminders.length !== 0 ? u.emailreminders.map(r => <Item key={r.timestamp}>{new Date(r.timestamp).toLocaleDateString()} - {r.type}</Item>) : <Item>No mails</Item>}
                              </List>
                              <br />
                              Email to send now
                              <Form.Select
                                name='emailTemplate'
                                value={emailTemplate}
                                onChange={handleMailTemplateChange}
                                placeholder='Email template to send'
                                options={emailTemplateOptions} />
                            </Modal.Description>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button color='red' onClick={() => setModalState(u.email, false)}>
                              <Icon name='cancel' /> Cancel
                            </Button>
                            <Button color='green' onClick={() => { sendMail({ firstName: u.firstName, email: u.email, type: emailTemplate }); setModalState(u.email, false) }}>
                              <Icon name='checkmark' /> Send
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      </Table.Cell>
                    </Table.Row>
                  )))}
            </Table.Body>
          </Table>) :
          (null)}
      </Grid.Column>
    </Grid>
  )
}

const AdminUserProfilePage = () => {
  let { id } = useParams();
  const history = useHistory();

  const userProfileQuery = useQuery(USER_PROFILE_QUERY, { variables: { id: id } });
  const [userProfile, setUserProfile] = useState({});

  const [shift, setShift] = useState({ volunteers: [] });

  useEffect(() => {
    if (userProfileQuery.data?.user) {
      setUserProfile({ ...userProfile, ...userProfileQuery.data.user[0] })
    }
    if (userProfileQuery.data?.user[0].captainShifts) {
      setShift({ ...shift, volunteers: userProfileQuery.data.user[0].captainShifts.map(cs => { return { shift: cs.shift, shiftType: cs.shiftType, user: cs.volunteer } }) });
    }
  }, [userProfileQuery]);

  const handleProfileChange = (e, data) => {
    setUserProfile({
      ...userProfile,
      [data.name]: data.value === undefined ? data.checked : data.value
    });
  }

  const userEventQuery = useQuery(USER_EVENT_QUERY, { variables: { userId: id } });
  const [userEvent, setUserEvent] = useState({ preferredBuddy: '' });

  useEffect(() => {
    if (userEventQuery.data?.user_event) {
      setUserEvent({ ...userEvent, ...userEventQuery.data.user_event[0] })
    }
  }, [userEventQuery]);

  const handleUserEventChange = (e, data) => {
    setUserEvent({
      ...userEvent,
      [data.name]: data.value === undefined ? data.checked : data.value
    });
  }

  const userActQuery = useQuery(USER_ACT_QUERY, { variables: { userId: id } });
  const [userAct, setUserAct] = useState({ actName: '', guestList: '' });

  useEffect(() => {
    if (userActQuery.data?.user_act) {
      setUserAct({ ...userAct, ...userActQuery.data.user_act[0] })
    }
  }, [userActQuery]);

  const handleUserActChange = (e, data) => {
    setUserAct({
      ...userAct,
      [data.name]: data.value === undefined ? data.checked : data.value
    });
  }

  useEffect(() => {
    console.log('Refetch Profile!');
    userProfileQuery.refetch();
    userEventQuery.refetch();
    userActQuery.refetch();
  }, [shift]);

  const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE);
  const [registerUserEvent] = useMutation(UPDATE_USER_EVENT);
  const [updateUserAct] = useMutation(UPDATE_USER_ACT);


  const usersWithShiftQuery = useQuery(USER_SHIFT_QUERY);
  const [usersWithShift, setUsersWithShift] = useState([]);

  // console.log(usersWithShift);

  useEffect(() => {
    if (usersWithShiftQuery.data?.user) {
      setUsersWithShift([...usersWithShift, ...usersWithShiftQuery.data.user])
    }
  }, [usersWithShiftQuery]);

  const handleShiftChange = (e, data) => {
    setShift({
      ...shift,
      [data.name]: data.value === undefined ? data.checked : data.value
    })
  }

  const handleRemoveVolunteer = (volunteer) => {
    setShift({
      ...shift,
      volunteers: shift.volunteers.filter(v => v !== volunteer)
    })

    upsertShiftInfo({
      variables: {
        captainId: id,
        objects: [...shift.volunteers.filter(v => v !== volunteer).map(v => {
          return {
            eventId: "48414295-ff52-4755-911d-a900b4e796e5",
            captainId: id,
            volunteerId: v.user.id,
            shift: v.shift,
            shiftType: v.shiftType
          }
        })]
      }
    }).then(res => {
      console.log(res);
      if (!res.data) return;
      // console.log(`${res.data.update_user_event.returning[0].user.firstName} assigned to ${changeData.value} (${res.data.update_user_event.affected_rows} rows)`);
      toast.success(`Shift data uploaded!`)
      // refetch();
    })
  }

  const [upsertShiftInfo] = useMutation(DELETE_INSERT_SHIFT_INFO);

  const handleShiftSubmit = (e) => {
    if (shift.shift && shift.shiftType && shift.volunteer && !shift.volunteers.find(v => v.user.id === JSON.parse(shift.volunteer).id)) {
      shift.volunteers.push({ shift: shift.shift, shiftType: shift.shiftType, user: JSON.parse(shift.volunteer) });
      setShift({ ...shift, volunteers: shift.volunteers, volunteer: undefined });

      upsertShiftInfo({
        variables: {
          captainId: id,
          objects: [...shift.volunteers.map(v => {
            return {
              eventId: "48414295-ff52-4755-911d-a900b4e796e5",
              captainId: id,
              volunteerId: v.user.id,
              shift: v.shift,
              shiftType: v.shiftType
            }
          })]
        }
      }).then(res => {
        console.log(res);
        if (!res.data) return;
        // console.log(`${res.data.update_user_event.returning[0].user.firstName} assigned to ${changeData.value} (${res.data.update_user_event.affected_rows} rows)`);
        toast.success(`Shift data uploaded!`)
        // refetch();
      })
    }
  }

  const handleSubmit = () => {
    console.log(userEvent);
    console.log(userAct);
    Promise.all([
      updateUserProfile({
        variables: {
          userId: id,
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          phone: userProfile.phone,
          active: userProfile.active,
          userType: userProfile.userType
        }
      }),
      userEvent?.userId ? registerUserEvent({
        variables: {
          userId: userEvent.userId,
          acceptRules: userEvent.acceptRules,
          firstTime: userEvent.firstTime,
          preferredRole: userEvent.preferredRole,
          preferredShift: userEvent.preferredShift,
          preferredBuddy: userEvent.preferredBuddy,
          arrivalDate: userEvent.arrivalDate,
          arrivalTime: userEvent.arrivalTime,
          arriveEarlier: userEvent.arriveEarlier,
          departureDate: userEvent.departureDate,
          departureTime: userEvent.departureTime,
          departLater: userEvent.departLater,
          biggerRole: userEvent.biggerRole,
          remarks: userEvent.remarks,
          callStatus: userEvent.callStatus,
          assignedTo: userEvent.assignedTo,
          phase: userEvent.phase,
          adminRemarks: userEvent.adminRemarks
        }
      }) : null,
      userAct?.userId ? updateUserAct({
        variables: {
          userId: userAct.userId,
          eventId: userAct.eventId,
          acceptRules: userAct.acceptRules,
          firstTime: userAct.firstTime,
          actName: userAct.actName,
          bandSize: userAct.bandSize !== '' ? userAct.bandSize : 0,
          estFuelCost: userAct.estFuelCost,
          guestList: userAct.guestList,
          arrivalDate: userAct.arrivalDate,
          arrivalTime: userAct.arrivalTime,
          arriveEarlier: userAct.arriveEarlier,
          departureDate: userAct.departureDate,
          departureTime: userAct.departureTime,
          departLater: userAct.departLater,
          needsBed: userAct.needsBed,
          remarks: userAct.remarks,
          musicStyle: userAct.musicStyle,
          musicPreview: userAct.musicPreview,
          gearNeed: userAct.gearNeed,
          gearBring: userAct.gearBring,
          feeEnough: userAct.feeEnough,
          callStatus: userAct.callStatus
        }
      }) : null
    ]).then(res => {
      console.log(res);
      if (!res[0].data || (res[1] !== null && !res[1].data) || (res[2] !== null && !res[2].data)) return;
      toast.success('Saved! Updated ' + res[0].data.update_user.returning[0].firstName + ' (' + res[0].data.update_user.returning[0].email + ')');
      history.goBack();
    })
  }

  useEffect(() => {
    if (userEventQuery.data) {
      setUserEvent(userEventQuery.data.user_event[0]);
    }
  }, [userEventQuery])

  const roleOptions = [
    { key: 'bar', value: 'bar', text: 'Bar' },
    { key: 'facility', value: 'facility', text: 'Facility' },
    { key: 'mood', value: 'mood', text: 'Mood Setting' },
    { key: 'parking', value: 'parking', text: 'Entrance / Parking' },
    { key: 'building', value: 'building', text: 'Building' }
  ]

  const shiftOptions = [
    { key: 'morning', value: 'morning', text: 'Morning shift' },
    { key: 'day', value: 'day', text: 'Day shift' },
    { key: 'night', value: 'night', text: 'Night shift' },
  ]

  const arrivalDateOptions = [
    { key: '2023-05-11', value: '2023-05-11', text: 'Thu 11 May (Building Crew)' },
    { key: '2023-05-12', value: '2023-05-12', text: 'Fri 12 May (Building Crew)' },
    { key: '2023-05-13', value: '2023-05-13', text: 'Sat 13 May (Building Crew)' },
    { key: '2023-05-14', value: '2023-05-14', text: 'Sun 14 May (Building Crew)' },
    { key: '2023-05-15', value: '2023-05-15', text: 'Mon 15 May (Building Crew)' },
    { key: '2023-05-16', value: '2023-05-16', text: 'Tue 16 May (Building Crew)' },
    { key: '2023-05-17', value: '2023-05-17', text: 'Wed 17 May' },
    { key: '2023-05-18', value: '2023-05-18', text: 'Thu 18 May' },
    { key: '2023-05-19', value: '2023-05-19', text: 'Fri 19 May' },
    { key: '2023-05-20', value: '2023-05-20', text: 'Sat 20 May' }
  ]

  const departureDateOptions = [
    { key: '2023-05-20', value: '2023-05-20', text: 'Sat 20 May' },
    { key: '2023-05-21', value: '2023-05-21', text: 'Sun 21 May' },
    { key: '2023-05-22', value: '2023-05-22', text: 'Mon 22 May' },
    { key: '2023-05-23', value: '2023-05-23', text: 'Tue 23 May (Breakdown Crew)' },
    { key: '2023-05-24', value: '2023-05-24', text: 'Wed 24 May (Breakdown Crew)' },
    { key: '2023-05-25', value: '2023-05-25', text: 'Thu 25 May (Breakdown Crew)' }
  ]

  const timeOfDayOptions = [
    { key: 'morning', value: 'morning', text: 'In the morning' },
    { key: 'afternoon', value: 'afternoon', text: 'In the afternoon' },
    { key: 'evening', value: 'evening', text: 'In the evening' },
    { key: 'night', value: 'night', text: 'In the night' }
  ]

  const userStatusOptions = [
    { key: 'success', value: 'success', text: 'Success' },
    { key: 'pending', value: 'pending', text: 'Pending' },
    { key: '1try', value: '1try', text: '1st try' },
    { key: '2try', value: '2try', text: '2nd try' },
    { key: '3try', value: '3try', text: '3rd try' },
    { key: 'email', value: 'email', text: 'Sent email' },
    { key: 'lasttry', value: 'lasttry', text: 'Last try' }
  ]

  const userTypeOptions = [
    { key: 'vis', value: 'vis', text: 'Visitor' },
    { key: 'zoo', value: 'zoo', text: 'Zookeeper' },
    { key: 'dj', value: 'dj', text: 'DJ' },
    { key: 'band', value: 'band', text: 'Band' }
  ]

  const assignedToOptions = [
    { key: 'domi', value: 'domi', text: 'Domi' },
    { key: 'team', value: 'team', text: 'Team' },
  ]

  const phaseOptions = [
    { key: 1, value: 1, text: 'No' },
    { key: 2, value: 2, text: 'Yes' }
  ]

  const bandSizeOptions = [
    { key: 1, value: 1, text: '1' },
    { key: 2, value: 2, text: '2' },
    { key: 3, value: 3, text: '3' },
    { key: 4, value: 4, text: '4' },
    { key: 5, value: 5, text: '5' },
    { key: 6, value: 6, text: '6' },
    { key: 7, value: 7, text: '7' },
    { key: 8, value: 8, text: '8' },
    { key: 9, value: 9, text: '9' },
    { key: 10, value: 10, text: '10' },
  ]

  const festivalNames = {
    jetztgeht: 'Jetzt Geht LosLosLosheim',
    bremsen: 'Alle Bremsen LosLosLosheim',
    leinen: 'Alle Leinen LosLosLosheim',
    hullen: 'Alle Hullen LosLosLosheim',
    schrauben: 'Alle Schrauben LosLosLosheim'
  }

  const shiftTypes = [
    { key: 'wunderbar', value: 'wunderbar', text: 'Wunderbar' },
    { key: 'muhle', value: 'muhle', text: 'Mühle' },
    { key: 'feuerbuhne', value: 'feuerbuhne', text: 'Feuerbühne' },
    { key: 'barback', value: 'barback', text: 'Bar Back' },
    { key: 'groundkeeper', value: 'groundkeeper', text: 'Groundkeeper' },
    { key: 'feuerfuhrer', value: 'feuerfuhrer', text: 'Feuerführer' },
    { key: 'breakfast', value: 'breakfast', text: 'Breakfast' },
    { key: 'silentdisco', value: 'silentdisco', text: 'Silent disco' },
    { key: 'lilmill', value: 'lilmill', text: 'Lil Mill' },
    { key: 'parking', value: 'parking', text: 'Parking' }
  ]

  const volunteerShifts = [
    { key: 'thu9-12', value: 'thu9-12', text: 'Thursday 09-12' },
    { key: 'thu12-15', value: 'thu12-15', text: 'Thursday 12-15' },
    { key: 'thu15-18', value: 'thu15-18', text: 'Thursday 15-18' },
    { key: 'thu18-21', value: 'thu18-21', text: 'Thursday 18-21' },
    { key: 'thu21-00', value: 'thu21-00', text: 'Thursday 21-00' },
    { key: 'fri00-03', value: 'fri00-03', text: 'Friday 00-03' },
    { key: 'fri09-12', value: 'fri09-12', text: 'Friday 09-12' },
    { key: 'fri12-15', value: 'fri12-15', text: 'Friday 12-15' },
    { key: 'fri15-18', value: 'fri15-18', text: 'Friday 15-18' },
    { key: 'fri18-21', value: 'fri18-21', text: 'Friday 18-21' },
    { key: 'fri21-00', value: 'fri21-00', text: 'Friday 21-00' },
    { key: 'sat00-03', value: 'sat00-03', text: 'Saturday 00-03' },
    { key: 'sat03-06', value: 'sat03-06', text: 'Saturday 03-06' },
    { key: 'sat09-12', value: 'sat09-12', text: 'Saturday 09-12' },
    { key: 'sat12-15', value: 'sat12-15', text: 'Saturday 12-15' },
    { key: 'sat15-18', value: 'sat15-18', text: 'Saturday 15-18' },
    { key: 'sat18-21', value: 'sat18-21', text: 'Saturday 18-21' },
    { key: 'sat21-00', value: 'sat21-00', text: 'Saturday 21-00' },
    { key: 'sun00-03', value: 'sun00-03', text: 'Sunday 00-03' },
    { key: 'sun03-07', value: 'sun03-07', text: 'Sunday 03-07' },
    { key: 'sun09-12', value: 'sun09-12', text: 'Sunday 09-12' },
    { key: 'sun12-15', value: 'sun12-15', text: 'Sunday 12-15' },
    { key: 'sun15-18', value: 'sun15-18', text: 'Sunday 15-18' },
    { key: 'sun18-21', value: 'sun18-21', text: 'Sunday 18-21' },
  ]

  return (
    <Grid textAlign='center' style={{ height: '100vh', width: '100%' }} verticalAlign='top'>
      <Grid.Column>
        <Message attached>
          <Button primary icon labelPosition='left' onClick={() => history.goBack()} style={{ position: 'absolute', left: '18px', top: '18px' }}>
            <Icon name='arrow left' />
            Back
          </Button>
          <Header as='h2' color='teal' textAlign='center'>
            Profile
          </Header>
          Details of this person
        </Message>
        <Table attached definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>{userProfile.firstName} {userProfile.lastName}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Email</Table.Cell>
              <Table.Cell>{userProfile.email}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Phone</Table.Cell>
              <Table.Cell>{userProfile.phone}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Active</Table.Cell>
              <Table.Cell>
                <Checkbox
                  toggle
                  checked={userProfile.active} />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Last Login</Table.Cell>
              <Table.Cell>{new Date(userProfile.lastLogin).toLocaleDateString()}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Attended</Table.Cell>
              <Table.Cell><ul>{userEvent?.attended ? Object.keys(userEvent.attended).map(f => {
                if (userEvent.attended[f]) {
                  return <li key={f}>{festivalNames[f]}</li>
                }
              }) : null}</ul></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <br />
        <Message attached>
          <Header as='h2' color='teal' textAlign='center'>
            Shift Info
          </Header>
        </Message>
        <Message attached='bottom' warning style={{ marginBottom: 0 }}>
          {userProfile.captainShifts?.length > 0
            ? (`USER IS CAPTAIN! Has shifts ${[...new Set(userProfile.captainShifts.map(cs => cs.shift))]} and types ${[...new Set(userProfile.captainShifts.map(cs => cs.shiftType))]}`)
            : userProfile.volunteerShifts?.length > 0
              ?
              <>
                <p>
                  {`USER IS VOLUNTEER! Has shift ${[...new Set(userProfile.volunteerShifts.map(cs => cs.shift))]} and type ${[...new Set(userProfile.volunteerShifts.map(cs => cs.shiftType))]}`}
                </p>
                <Button
                  type='button'
                  color='blue'
                  size='large'
                  // floated='right'
                  icon='user secret'
                  labelPosition='left'
                  content={`Captain ${userProfile.volunteerShifts[0]?.captain.firstName}  ${userProfile.volunteerShifts[0]?.captain.lastName}`}
                  onClick={() => history.push('/users/' + userProfile.volunteerShifts[0]?.captain.id)} />
              </>
              : ('USER HAS NO SHIFT')}
        </Message>
        <br />

        {userProfile.volunteerShifts?.length === 0 ?
          <>
            <Message attached>
              <Header as='h2' color='teal' textAlign='center'>
                {shift.volunteers.length === 0 ? 'Not a Captain' : `Captain of ${shift.volunteers.length} Volunteers`}
              </Header>
              {shift.volunteers.length === 0 ? 'Add volunteers to create a captain' : 'Remove volunteers to remove captain'}
            </Message>
            <Form><Table>
              {shift.volunteers.length > 0 ? <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width={1}>Shift</Table.HeaderCell>
                  <Table.HeaderCell width={1}>Shift Type</Table.HeaderCell>
                  <Table.HeaderCell width={5}>Name</Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                  <Table.HeaderCell width={1}></Table.HeaderCell>
                </Table.Row>
              </Table.Header> : null}
              <Table.Body>
                {shift.volunteers.map(v => (
                  <Table.Row key={v.user.id}>
                    <Table.Cell>{v.shift}</Table.Cell>
                    <Table.Cell>{v.shiftType}</Table.Cell>
                    <Table.Cell>{v.user.firstName} {v.user.lastName} ({v.user.email})</Table.Cell>
                    <Table.Cell>
                      <Button
                        type='button'
                        color='blue'
                        size='large'
                        floated='right'
                        icon='user'
                        labelPosition='right'
                        content='Profile'
                        onClick={() => history.push('/users/' + v.user.id)} />
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        type='button'
                        color='red'
                        size='large'
                        floated='right'
                        icon='close'
                        labelPosition='right'
                        content='Remove'
                        onClick={() => handleRemoveVolunteer(v)} />
                    </Table.Cell>
                  </Table.Row>
                ))}
                {userEvent ? <Table.Row>
                  <Table.Cell>Add Volunteer</Table.Cell>
                  <Table.Cell colSpan={4}>
                    <Form.Group widths='equal' style={{ marginBottom: '0px' }}>
                      <Form.Select
                        //label=''
                        name='shift'
                        value={shift.shift}
                        onChange={handleShiftChange}
                        placeholder='Assigned Shift'
                        options={volunteerShifts}
                      />
                      <Form.Select
                        //label=''
                        name='shiftType'
                        value={shift.shiftType}
                        onChange={handleShiftChange}
                        placeholder='Assigned Shift Type'
                        options={shiftTypes}
                      />
                      <Dropdown
                        placeholder='Volunteers'
                        name='volunteer'
                        fluid
                        search
                        selection
                        options={usersWithShift.filter(u => u.volunteerShifts.length === 0).map(us => { return { text: `${us.firstName} ${us.lastName} (${us.email})`, value: JSON.stringify(us), key: us.id } })}
                        value={shift.volunteer || ''}
                        onChange={handleShiftChange}
                      />
                      <Button
                        type='button'
                        color='green'
                        size='large'
                        floated='right'
                        icon='check'
                        labelPosition='right'
                        content='Add'
                        onClick={handleShiftSubmit} />
                    </Form.Group>
                  </Table.Cell>
                </Table.Row> : null}
              </Table.Body>
            </Table>
            </Form>
            <br />
          </> : null}


        {userEvent?.signedUp ? <>
          <Message attached>
            <Header as='h2' color='teal' textAlign='center'>
              Alle Tiere LosLosLosheim
            </Header>
            Signed Up: {userEvent.signedUp?.substring(0, 10)} &nbsp; &nbsp; Last Update: {userEvent.updated_at?.substring(0, 10)}
          </Message>
          <Form>
            <Table attached definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Accept Rules</Table.Cell>
                  <Table.Cell>
                    {/* {userEvent.acceptRules ? <Icon name='checkmark' /> : <Icon name='remove' />} */}
                    <Form.Select
                      name='acceptRules'
                      value={userEvent.acceptRules}
                      onChange={handleUserEventChange}
                      placeholder='Accept Rules?'
                      options={[
                        { key: false, value: false, text: 'No' },
                        { key: true, value: true, text: 'Yes' }
                      ]} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>First Time</Table.Cell>
                  <Table.Cell>
                    {/* {userEvent.firstTime ? <Icon name='checkmark' /> : <Icon name='remove' />} */}
                    <Form.Select
                      name='firstTime'
                      value={userEvent.firstTime}
                      onChange={handleUserEventChange}
                      placeholder='First Time?'
                      options={[
                        { key: false, value: false, text: 'No' },
                        { key: true, value: true, text: 'Yes' }
                      ]} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Preferred Role</Table.Cell>
                  <Table.Cell>
                    <Form.Select
                      //label='Preferred Role'
                      name='preferredRole'
                      value={userEvent.preferredRole}
                      onChange={handleUserEventChange}
                      placeholder='The preferred role'
                      options={roleOptions} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Preferred Shift</Table.Cell>
                  <Table.Cell>
                    <Form.Select
                      //label='Preferred Shift'
                      name='preferredShift'
                      value={userEvent.preferredShift}
                      onChange={handleUserEventChange}
                      placeholder='The preferred shift'
                      options={shiftOptions} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Preferred Buddy</Table.Cell>
                  <Table.Cell>
                    <Form.Input
                      //label='Preferred Buddy'
                      type='text'
                      name='preferredBuddy'
                      value={userEvent.preferredBuddy}
                      onChange={handleUserEventChange}
                      fluid
                      //icon='phone'
                      //iconPosition='left'
                      placeholder='Name of preferred buddy'
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Arrival</Table.Cell>
                  <Table.Cell>
                    <Form.Group widths='equal' style={{ marginBottom: '0px' }}>
                      <Form.Select
                        //label='Arrival Date'
                        name='arrivalDate'
                        value={userEvent.arrivalDate}
                        onChange={handleUserEventChange}
                        placeholder='Arrival Date'
                        options={arrivalDateOptions}
                      />
                      <Form.Select
                        //label='Arrival Time'
                        name='arrivalTime'
                        value={userEvent.arrivalTime}
                        onChange={handleUserEventChange}
                        placeholder='Arrival Time Of Day'
                        options={timeOfDayOptions}
                      />
                    </Form.Group>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Can Arrive Earlier</Table.Cell>
                  <Table.Cell>
                    {/* {userEvent.arriveEarlier ? <Icon name='checkmark' /> : <Icon name='remove' />} */}
                    <Form.Select
                      name='arriveEarlier'
                      value={userEvent.arriveEarlier}
                      onChange={handleUserEventChange}
                      placeholder='Arrive Earlier?'
                      options={[
                        { key: false, value: false, text: 'No' },
                        { key: true, value: true, text: 'Yes' }
                      ]} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Departure</Table.Cell>
                  <Table.Cell>
                    <Form.Group widths='equal' style={{ marginBottom: '0px' }}>
                      <Form.Select
                        //label='Departure Date'
                        name='departureDate'
                        value={userEvent.departureDate}
                        onChange={handleUserEventChange}
                        placeholder='Departure Date'
                        options={departureDateOptions}
                      />
                      <Form.Select
                        //label='Departure Time'
                        name='departureTime'
                        value={userEvent.departureTime}
                        onChange={handleUserEventChange}
                        placeholder='Departure Time Of Day'
                        options={timeOfDayOptions}
                      />
                    </Form.Group>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Can Depart Later</Table.Cell>
                  <Table.Cell>
                    {/* {userEvent.departLater ? <Icon name='checkmark' /> : <Icon name='remove' />} */}
                    <Form.Select
                      name='departLater'
                      value={userEvent.departLater}
                      onChange={handleUserEventChange}
                      placeholder='Depart Later?'
                      options={[
                        { key: false, value: false, text: 'No' },
                        { key: true, value: true, text: 'Yes' }
                      ]} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Wants Zookeeper</Table.Cell>
                  <Table.Cell>
                    {/* {userEvent.biggerRole ? <Icon name='checkmark' /> : <Icon name='remove' />} */}
                    <Form.Select
                      name='biggerRole'
                      value={userEvent.biggerRole}
                      onChange={handleUserEventChange}
                      placeholder='Bigger Role?'
                      options={[
                        { key: false, value: false, text: 'No' },
                        { key: true, value: true, text: 'Yes' }
                      ]} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Remarks</Table.Cell>
                  <Table.Cell>{userEvent.remarks}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Call Availability</Table.Cell>
                  <Table.Cell>
                    <Table definition unstackable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell />
                          {/* <Table.HeaderCell>Before 12:00</Table.HeaderCell> */}
                          <Table.HeaderCell textAlign='center'>12:00 - 19:00</Table.HeaderCell>
                          <Table.HeaderCell textAlign='center'>After 20:00</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>Monday</Table.Cell>
                          <Table.Cell textAlign='center' style={{ backgroundColor: userEvent.callMatrix?.mo1 ? 'green' : 'red ' }}>
                          </Table.Cell>
                          <Table.Cell textAlign='center' style={{ backgroundColor: userEvent.callMatrix?.mo2 ? 'green' : 'red ' }}>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Tuesday</Table.Cell>
                          <Table.Cell textAlign='center' style={{ backgroundColor: userEvent.callMatrix?.tu1 ? 'green' : 'red ' }}>
                          </Table.Cell>
                          <Table.Cell textAlign='center' style={{ backgroundColor: userEvent.callMatrix?.tu2 ? 'green' : 'red ' }}>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Wednesday</Table.Cell>
                          <Table.Cell textAlign='center' style={{ backgroundColor: userEvent.callMatrix?.we1 ? 'green' : 'red ' }}>
                          </Table.Cell>
                          <Table.Cell textAlign='center' style={{ backgroundColor: userEvent.callMatrix?.we2 ? 'green' : 'red ' }}>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Thursday</Table.Cell>
                          <Table.Cell textAlign='center' style={{ backgroundColor: userEvent.callMatrix?.th1 ? 'green' : 'red ' }}>
                          </Table.Cell>
                          <Table.Cell textAlign='center' style={{ backgroundColor: userEvent.callMatrix?.th2 ? 'green' : 'red ' }}>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Friday</Table.Cell>
                          <Table.Cell textAlign='center' style={{ backgroundColor: userEvent.callMatrix?.fr1 ? 'green' : 'red ' }}>
                          </Table.Cell>
                          <Table.Cell textAlign='center' style={{ backgroundColor: userEvent.callMatrix?.fr2 ? 'green' : 'red ' }}>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Weekend</Table.Cell>
                          <Table.Cell textAlign='center' style={{ backgroundColor: userEvent.callMatrix?.wknd1 ? 'green' : 'red ' }}>
                          </Table.Cell>
                          <Table.Cell textAlign='center' style={{ backgroundColor: userEvent.callMatrix?.wknd2 ? 'green' : 'red ' }}>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Form>
          <br />
        </> : null}

        {userAct.created_at ? <>
          <Message attached>
            <Header as='h2' color='teal' textAlign='center'>
              Artist Alle Tiere LosLosLosheim
            </Header>
            Signed Up: {userAct.created_at?.substring(0, 10)} &nbsp; &nbsp; Last Update: {userAct.updated_at?.substring(0, 10)}
          </Message>
          <Form>
            <Table attached definition>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>Act Name</Table.Cell>
                  <Table.Cell>
                    <Form.Input
                      type='text'
                      name='actName'
                      value={userAct.actName}
                      onChange={handleUserActChange}
                      placeholder='Act Name'
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Accept Rules</Table.Cell>
                  <Table.Cell>
                    <Form.Select
                      name='acceptRules'
                      value={userAct.acceptRules}
                      onChange={handleUserActChange}
                      placeholder='Accept Rules?'
                      options={[
                        { key: false, value: false, text: 'No' },
                        { key: true, value: true, text: 'Yes' }
                      ]} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>First Time</Table.Cell>
                  <Table.Cell>
                    <Form.Select
                      name='firstTime'
                      value={userAct.firstTime}
                      onChange={handleUserActChange}
                      placeholder='First Time?'
                      options={[
                        { key: false, value: false, text: 'No' },
                        { key: true, value: true, text: 'Yes' }
                      ]} />
                  </Table.Cell>
                </Table.Row>
                {userProfile.userType === 'band' ?
                  <Table.Row>
                    <Table.Cell>Band size</Table.Cell>
                    <Table.Cell>
                      <Form.Select
                        // label='Size of your band'
                        name='bandSize'
                        value={userAct.bandSize}
                        onChange={handleUserActChange}
                        fluid
                        placeholder='How many band members?'
                        options={bandSizeOptions}
                      />
                    </Table.Cell>
                  </Table.Row>
                  : null}
                <Table.Row>
                  <Table.Cell>Accepts Compensation</Table.Cell>
                  <Table.Cell>{userAct.feeEnough ? 'Yes' : 'NO!!'}</Table.Cell>
                </Table.Row>
                {userProfile.userType === 'band' ?
                  <Table.Row>
                    <Table.Cell>Estimated Fuel Cost</Table.Cell>
                    <Table.Cell>
                      <Form.Input
                        // label='Estimated fuel cost'
                        name='estFuelCost'
                        value={userAct.estFuelCost}
                        onChange={handleUserActChange}
                        fluid
                        placeholder='20 €'
                      />
                    </Table.Cell>
                  </Table.Row>
                  : null}
                <Table.Row>
                  <Table.Cell>Guestlist</Table.Cell>
                  <Table.Cell>
                    <Form.Input
                      type='text'
                      name='guestList'
                      value={userAct.guestList}
                      onChange={handleUserActChange}
                      placeholder='Guestlist'
                    />
                  </Table.Cell>
                </Table.Row>
                {userProfile.userType === 'dj' ?
                  <>
                    <Table.Row>
                      <Table.Cell>Music Style</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          type='text'
                          name='musicStyle'
                          value={userAct.musicStyle}
                          onChange={handleUserActChange}
                          placeholder='Music Style'
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Music Preview</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          type='text'
                          name='musicPreview'
                          value={userAct.musicPreview}
                          onChange={handleUserActChange}
                          placeholder='Music Preview'
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Needs Gear</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          type='text'
                          name='gearNeed'
                          value={userAct.gearNeed}
                          onChange={handleUserActChange}
                          placeholder='Gear Needed'
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Guestlist</Table.Cell>
                      <Table.Cell>
                        <Form.Input
                          type='text'
                          name='gearBring'
                          value={userAct.gearBring}
                          onChange={handleUserActChange}
                          placeholder='Gear brought'
                        />
                      </Table.Cell>
                    </Table.Row>
                  </> : null}
                <Table.Row>
                  <Table.Cell>Arrival</Table.Cell>
                  <Table.Cell>
                    <Form.Group widths='equal' style={{ marginBottom: '0px' }}>
                      <Form.Select
                        //label='Arrival Date'
                        name='arrivalDate'
                        value={userAct.arrivalDate}
                        onChange={handleUserActChange}
                        placeholder='Arrival Date'
                        options={arrivalDateOptions}
                      />
                      <Form.Select
                        //label='Arrival Time'
                        name='arrivalTime'
                        value={userAct.arrivalTime}
                        onChange={handleUserActChange}
                        placeholder='Arrival Time Of Day'
                        options={timeOfDayOptions}
                      />
                    </Form.Group>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Can Arrive Earlier</Table.Cell>
                  <Table.Cell>
                    <Form.Select
                      name='arriveEarlier'
                      value={userAct.arriveEarlier}
                      onChange={handleUserActChange}
                      placeholder='Arrive Earlier?'
                      options={[
                        { key: false, value: false, text: 'No' },
                        { key: true, value: true, text: 'Yes' }
                      ]} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Departure</Table.Cell>
                  <Table.Cell>
                    <Form.Group widths='equal' style={{ marginBottom: '0px' }}>
                      <Form.Select
                        //label='Departure Date'
                        name='departureDate'
                        value={userAct.departureDate}
                        onChange={handleUserActChange}
                        placeholder='Departure Date'
                        options={departureDateOptions}
                      />
                      <Form.Select
                        //label='Departure Time'
                        name='departureTime'
                        value={userAct.departureTime}
                        onChange={handleUserActChange}
                        placeholder='Departure Time Of Day'
                        options={timeOfDayOptions}
                      />
                    </Form.Group>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Can Depart Later</Table.Cell>
                  <Table.Cell>
                    {/* {userEvent.departLater ? <Icon name='checkmark' /> : <Icon name='remove' />} */}
                    <Form.Select
                      name='departLater'
                      value={userAct.departLater}
                      onChange={handleUserActChange}
                      placeholder='Depart Later?'
                      options={[
                        { key: false, value: false, text: 'No' },
                        { key: true, value: true, text: 'Yes' }
                      ]} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Needs Bed</Table.Cell>
                  <Table.Cell>
                    <Form.Select
                      name='needsBed'
                      value={userAct.needsBed}
                      onChange={handleUserActChange}
                      placeholder='Needs bed'
                      options={[
                        { key: false, value: false, text: 'No' },
                        { key: true, value: true, text: 'Yes' }
                      ]} />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Remarks</Table.Cell>
                  <Table.Cell>{userAct.remarks}</Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Form>
          <br />
        </> : null}

        {userEvent ? <>
          <Message attached>
            <Header as='h2' color='teal' textAlign='center'>
              Administration
            </Header>
          </Message>
          <Form>
            <Table attached definition>
              <Table.Body>
                {/* {userEvent ? <Table.Row>
                <Table.Cell>Assigned To</Table.Cell>
                <Table.Cell>
                  <Form.Select
                    name='assignedTo'
                    value={userEvent.assignedTo}
                    onChange={handleUserEventChange}
                    placeholder='Assigned To'
                    options={assignedToOptions}
                  />

                </Table.Cell>
              </Table.Row> : null} */}
                {/* {userEvent ? <Table.Row>
                <Table.Cell>Status</Table.Cell>
                <Table.Cell>
                  <Form.Select
                    name='callStatus'
                    value={userEvent.callStatus}
                    onChange={handleUserEventChange}
                    placeholder='Status'
                    options={userStatusOptions}
                  />
                </Table.Cell>
              </Table.Row> : null} */}
                {/* {userAct ? <Table.Row>
                <Table.Cell>Artist Status</Table.Cell>
                <Table.Cell>
                  <Form.Select
                    name='callStatus'
                    value={userAct.callStatus}
                    onChange={handleUserActChange}
                    placeholder='Status'
                    options={userStatusOptions}
                  />
                </Table.Cell>
              </Table.Row> : null} */}
                {/* <Table.Row>
                <Table.Cell>Type</Table.Cell>
                <Table.Cell>
                  <Form.Select
                    //label=''
                    name='userType'
                    value={userProfile.userType}
                    onChange={handleShiftChange}
                    placeholder='User Type'
                    options={userTypeOptions}
                  />
                </Table.Cell>
              </Table.Row> */}
                {/* {userEvent ? <Table.Row>
                <Table.Cell>Can buy ticket</Table.Cell>
                <Table.Cell>
                  <Form.Select
                    //label=''
                    name='phase'
                    value={userEvent.phase}
                    onChange={handleUserEventChange}
                    placeholder='Can buy ticket'
                    options={phaseOptions}
                  />
                </Table.Cell>
              </Table.Row> : null} */}
                {/* {userEvent ? <Table.Row>
                <Table.Cell>Shift</Table.Cell>
                <Table.Cell>
                  <Form.Select
                    //label=''
                    name='shift'
                    value={shift.shift}
                    onChange={handleShiftChange}
                    placeholder='Assigned Shift'
                    options={volunteerShifts}
                  />
                </Table.Cell>
              </Table.Row> : null}
              {userEvent ? <Table.Row>
                <Table.Cell>Shift Type</Table.Cell>
                <Table.Cell>
                  <Form.Select
                    //label=''
                    name='shiftType'
                    value={shift.shiftType}
                    onChange={handleShiftChange}
                    placeholder='Assigned Shift Type'
                    options={shiftTypes}
                  />
                </Table.Cell>
              </Table.Row> : null} */}
                <Table.Row>
                  <Table.Cell>Notes</Table.Cell>
                  <Table.Cell>
                    <Form.TextArea
                      name='adminRemarks'
                      value={userEvent.adminRemarks || ''}
                      onChange={handleUserEventChange}
                      placeholder='Notes for this user'
                    />
                  </Table.Cell>
                </Table.Row>
                {/* <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell>
                  <Button
                    type='button'
                    color='green'
                    size='large'
                    floated='right'
                    icon='check'
                    labelPosition='right'
                    content='Update Shift Info'
                    onClick={handleUpdateShift} />
                </Table.Cell>
              </Table.Row> */}
                <Table.Row>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>
                    <Button
                      type='button'
                      color='green'
                      size='large'
                      floated='right'
                      icon='check'
                      labelPosition='right'
                      content='Update Profile'
                      onClick={handleSubmit} />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Form>
        </> : null}
      </Grid.Column>
    </Grid >
  )
}

const AdminEventOverviewPage = () => {
  const { loading, error, data } = useQuery(EVENT_QUERY);

  if (error) return (
    <Grid textAlign='center'>
      <Message compact>
        <Message.Header>Database Error</Message.Header>
        {error.graphQLErrors.map(({ message }, i) => (
          <span key={i}>{message}</span>
        ))}
      </Message>
    </Grid>
  )

  return (
    <Grid textAlign='center' style={{ height: '100vh', width: '100%' }}>
      <Grid.Column>
        <Message>
          <Header as='h2' color='teal' textAlign='center'>
            Events
          </Header>
          Overview of your Events
        </Message>
        <div>
          <Input icon='search' placeholder='Search...' />
          {/* <Button primary icon floated='right' labelPosition='left'>
            <Icon name='plus' />
            New Event
          </Button> */}
        </div>
        <Table celled selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Data</Table.HeaderCell>
              <Table.HeaderCell>Visible</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {loading ? (<Table.Row><Table.Cell>Loading...</Table.Cell></Table.Row>) : (data.event.map(u => (
              <Table.Row key={u.id} negative={!u.visible}>
                <Table.Cell>{u.eventName}</Table.Cell>
                <Table.Cell>{u.eventDate}</Table.Cell>
                <Table.Cell>{u.visible ? <Icon name='checkmark' /> : <Icon name='close' />}</Table.Cell>
              </Table.Row>
            )))}
          </Table.Body>
        </Table>
        {/* <Message attached='bottom' info>
      New to us? <Link to='/register'>Sign Up</Link>
    </Message> */}
      </Grid.Column>
    </Grid>
  )
}

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ToastContainer />
      <Router>
        {/* <input type="checkbox" id="menu" /> */}
        <header>25feb<label htmlFor="menu"></label></header>
        <main>
          <aside>
            <AdminPageMenu />
          </aside>
          <section>
            <Switch id="inner-flex-container">
              <Route path="/login" component={LoginPage} />
              <PrivateRoute path="/users/:id" component={AdminUserProfilePage} />
              <PrivateRoute path="/users" component={AdminUserOverviewPage} />
              {/* <PrivateRoute path="/artists" component={AdminArtistOverviewPage} /> */}
              <PrivateRoute path="/captains" component={AdminCaptainOverviewPage} />
              <PrivateRoute path="/events" component={AdminEventOverviewPage} />
              <PrivateRoute path="/" component={AdminHomePage} />
            </Switch>
          </section>
        </main>
        {/* <footer>footer</footer> */}
        <div style={{ fontSize: '10px', position: 'absolute', bottom: '2px', right: '2px' }}>
          {generatedGitInfo.gitBranch}@{generatedGitInfo.gitCommitHash}
        </div>
      </Router>
    </ApolloProvider>
  )
}

export default App;
